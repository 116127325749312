import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { concatMap, Observable } from 'rxjs';
import { OverlayService } from '../services/overlay.service';
import { EMPTY } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DeleteComponent } from '../components/shared/dialog/delete/delete.component';
@Injectable()
export class DelteInterceptor implements HttpInterceptor {

  constructor(private dialog: MatDialog) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //TODO CHeck if this is doable
    // if (request.method == "DELETE") {

    //   this.dialog.open(DeleteComponent, {
    //     minWidth: '30vw',
    //     maxWidth: '350px',
    //     autoFocus: true,
    //     data: { title: "Löschen", message: "Wollen Sie dein Eintrag wirklich löschen?", okText: "Weiter", cancelText: "Abbrechen" }
    //   }).afterClosed().pipe(
    //     concatMap(() => next.handle(request))
    //   ).subscribe();

    // } else {
    //   return next.handle(request);
    // }
    return next.handle(request);
  }
}
