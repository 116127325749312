<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content>
    <div class="container">
        <div class="row">
            <h5>Beim Ausführen dieser Aktion werden alle Aktionsstatus anhand folgender Regeln aktualisiert:</h5>
            <mat-list role="list">
                <mat-list-item role="listitem">Vorschau >> Aktionsstart in 14 Tagen oder weniger</mat-list-item>
                <mat-list-item role="listitem">Aktiv >> Aktionsstart heute oder überschritten</mat-list-item>
                <mat-list-item role="listitem">Abgelaufen >> Aktionsende gestern oder älter</mat-list-item>
                <mat-list-item role="listitem">Archiviert >> Aktionsende vor 3 Tagen oder mehr</mat-list-item>
            </mat-list>
        </div>
    </div>
</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">{{data.cancelText}}</button>
    <button mat-button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial (click)="update()">{{ data.okText
        }}</button>
</div>