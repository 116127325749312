import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountState } from 'src/app/enums/account-state.enum';
import { BusinessUserResource } from 'src/app/interfaces/business-user-resource';
import { BusinessuserService } from 'src/app/services/businessuser.service';
import { OverlayService } from 'src/app/services/overlay.service';

interface DialogData {
  title: string;
  message: string;
  okText: string;
  head: BusinessUserResource;
}

@Component({
  selector: 'app-add-house-holdmember',
  templateUrl: './add-house-holdmember.component.html',
  styleUrls: ['./add-house-holdmember.component.scss']
})


export class AddHouseHoldmemberComponent implements OnInit {
  membersForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddHouseHoldmemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    private overlayService: OverlayService,
    private businessuserService: BusinessuserService
  ) {
    this.membersForm = this.fb.group({
      mobile: ['', []],
      email: ['', [Validators.email]],
      account: this.fb.group({
        username: ['', []],
      }),
      person: this.fb.group({
        gender: ['', [Validators.required]],
        birthday: ['', [Validators.required]],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        title: ['', []],
      }),
      address: this.fb.group({
        street: ['', [Validators.required, Validators.pattern(/^([\S\s]+?)\s+([\d-]*)\s*([\w])?$/)]],
        postcode: ['', [Validators.required, Validators.maxLength(5), Validators.minLength(5), Validators.pattern(/^[0-9]\d*$/)]],
        city: ['', [Validators.required]],
        countryCode: ['DE', []],
        location: this.fb.group({
          lat: ['', []],
          lng: ['', []],
        })
      })
    });
  }

  public genderOptions = [
    { value: 'MALE', viewValue: 'Herr' },
    { value: 'FEMALE', viewValue: 'Frau' },
    { value: 'UNDEFINED', viewValue: 'Divers' },
  ]

  ngOnInit(): void {
    console.log("DATA", this.data)
    this.membersForm.patchValue({
      address: {
        street: this.data.head?.address?.street,
        postcode: this.data.head?.address?.postcode,
        city: this.data.head?.address?.city,
      }
    })

    this.membersForm.controls.address['controls'].street.disable();
    this.membersForm.controls.address['controls'].postcode.disable();
    this.membersForm.controls.address['controls'].city.disable();
  }

  cancel(): void {
    this.dialogRef.close();
  }
  createMemberAndAddToHousehold() {

    this.membersForm.markAllAsTouched();
    if (this.membersForm.invalid) {
      return
    }

    let t: BusinessUserResource = {
      "account": {
        "username": this.membersForm.value.email,
      },
      "person": {
        "title": this.membersForm.value.person.title,
        "firstName": this.membersForm.value.person.firstName,
        "lastName": this.membersForm.value.person.lastName,
        "birthday": this.membersForm.value.person.birthday,
        "gender": this.membersForm.value.person.gender,
      },
      "address": {
        "street": this.membersForm.getRawValue().address?.street,
        "postcode": this.membersForm.getRawValue().address?.postcode,
        "city": this.membersForm.getRawValue().address?.city,
        "countryCode": "DE",
        "location": null
      },
      "email": this.membersForm.value.email,
      "phone": this.membersForm.value.mobile,
      "mobile": this.membersForm.value.mobile,
      "businessRole": "SUBCUSTOMER",
    }

    this.businessuserService.addToCustomerGroup(this.data.head, t).subscribe(fromAdd => {
      this.overlayService.openSnackBar("Miglied erfolgreich angelegt!");
      this.cancel();
    })

  }

}
