import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { HttpStateService, HttpProgressState } from '../services/http-state.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap, retryWhen, take, delay, finalize, catchError } from 'rxjs/operators';
import { OverlayService } from '../services/overlay.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  private exceptions: string[] = [
    'login'
  ];

  constructor(
    private httpStateService: HttpStateService,
    private overlay: OverlayService,
    private router: Router) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.httpStateService.state.next({
      url: request.url,
      state: HttpProgressState.start
    });

    let element = document.getElementById('Sidenav')
    if(element){
      element.style.overflow = 'hidden';
    }
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
         // console.log("Error in InterceptorService", error);
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
         //   console.log('this is client side error');
            errorMsg = `Error: ${error.error.message}`;
          }
          else {
           // console.log('this is server side error');
            if (error?.error?.message != undefined) {
              this.overlay.openSnackBar(error.error.message)
            } else {
              this.overlay.openSnackBar("Es ist ein unerwarteter Fehler aufgetreten, bitte versuchen Sie es erneut.")
            }
          }
          return throwError(() =>  error);
        }), finalize(() => {
          let element = document.getElementById('Sidenav')
          if(element){
            element.style.overflow = 'auto';
          }
          this.httpStateService.state.next({
            url: request.url,
            state: HttpProgressState.end
          });
        }
      ))
  }
}