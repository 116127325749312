import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateFilterInterest'
})
export class TranslateFilterInterestPipe implements PipeTransform {
  transform(value: string, type: string): string {
    if (type == 'filterState') {
      switch (value) {
        case 'ACTIVE':
          return "Aktiv";
        case 'DELETED':
          return "Gelöscht";
        default:
          return value;
      }

    }
  }

}
