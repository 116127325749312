import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventResource } from 'src/app/interfaces/event-resource';
import { EventService } from 'src/app/services/event.service';


export interface DialogData {
  title: string;
  message: string;
  okText: string;
  cancelText: String;
  event: EventResource;
}

@Component({
  selector: 'app-auto-update-event-status',
  templateUrl: './auto-update-event-status.component.html',
  styleUrls: ['./auto-update-event-status.component.scss']
})
export class AutoUpdateEventStatusComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AutoUpdateEventStatusComponent>,
    private eventService:EventService
  ) {

  }


  ngOnInit(): void {
  }
  cancel(): void {
    this.dialogRef.close();
  }

  update() {
    this.eventService.buldUpdateEvents(false).subscribe(val => {
      console.log(val)
    })
  }
}
