import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  constructor(
    private http: HttpClient,
    private environment: EnvironmentService) {

  }

  getAllVoucherCodesToEvent(publicid): Observable<any> {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event/${publicid}/external-voucher-key-configuration`)
      )
    )
  }

  saveVoucherCodes(publicid, VoucherUpdate, type): Observable<any> {
    const body = {
      voucherKeyStrategy: type,
      externalVoucherKeys: VoucherUpdate
    }

    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/event/${publicid}/external-voucher-key-configuration`, body)
      )
    )
  }
}
