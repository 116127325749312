import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { DialogComponent } from '../components/shared/dialog/dialog.component';
import { EditVoucherCodesDialogComponent } from '../components/shared/dialog/edit-voucher-codes-dialog/edit-voucher-codes-dialog.component';
import { ShowVoucherCodesComponent } from '../components/shared/dialog/show-voucher-codes/show-voucher-codes.component'
import { ChangeEventStateComponent } from '../components/shared/bottomSheets/change-event-state/change-event-state.component'
import { ChangeBusinessEntityStateComponent } from '../components/shared/bottomSheets/change-business-entity-state/change-business-entity-state.component'
import { ChangeBusinessUserStateComponent } from '../components/shared/bottomSheets/change-business-user-state/change-business-user-state.component'
import { ChangeBusinessUserRoleComponent } from '../components/shared/bottomSheets/change-business-user-role/change-business-user-role.component'
import { AddHouseHoldmemberComponent } from '../components/shared/dialog/add-house-holdmember/add-house-holdmember.component'
import { ResetPWForUserComponent } from '../components/shared/dialog/reset-pwfor-user/reset-pwfor-user.component'
import { ShowQrCodeComponent } from '../components/shared/dialog/show-qr-code/show-qr-code.component'

import { Observable } from 'rxjs';
import { EventResource } from '../interfaces/event-resource';
import { DeleteDialogComponent } from '../components/shared/dialog/delete-dialog/delete-dialog.component';
import { ErrorDialogComponent } from '../components/shared/dialog/error-dialog/error-dialog.component';
import { BusinessEntityResource } from '../interfaces/business-entity-resource';
import { BusinessUserResource } from '../interfaces/business-user-resource';
import { AddCategoriesComponent } from '../components/shared/dialog/add-categories/add-categories.component';
import { ShowCardQrCodeComponent } from '../components/shared/dialog/show-card-qr-code/show-card-qr-code.component';
import { CardResource } from '../interfaces/card-resouce';
import { DeleteComponent } from '../components/shared/dialog/delete/delete.component';
import { AutoUpdateEventStatusComponent } from '../components/shared/dialog/auto-update-event-status/auto-update-event-status.component';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  constructor(
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet) { }

  openSnackBar(msg, cssClass = 'default', duration = 5000) {
    this._snackBar.open(msg, null, { duration: duration, horizontalPosition: 'center', verticalPosition: 'bottom', panelClass: cssClass });
  }

  openDialog(title, message, okText): Observable<any> {
    const dialogRef = this.dialog.open(DialogComponent, {
      minWidth: '30vw',
      maxWidth: '50vw',
      data: { title, message, okText }
    });

    return dialogRef.afterClosed();
  }

  openDeleteDialog(title, message, okText, cancelText, object, type): Observable<any> {
    if (type == 'members') {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: { title, message, okText, cancelText, object, type }
      });
      return dialogRef.afterClosed();
    } else if (type == 'channel') {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: { title, message, okText, cancelText, object, type }
      });
      return dialogRef.afterClosed();
    } else if (type == 'openingHours') {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: { title, message, okText, cancelText, object, type }
      });
      return dialogRef.afterClosed();
    } else if (type == 'boxes') {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: { title, message, okText, cancelText, object, type }
      });
      return dialogRef.afterClosed();
    }
  }

  openAddCategoriesDialog(title, okText, cancelText, event: EventResource) {
    const dialogRef = this.dialog.open(AddCategoriesComponent, {
      minWidth: '30vw',
      maxWidth: '40vw',
      minHeight: '40vh',
      autoFocus: true,
      data: { title, okText, cancelText, event }
    });
    return dialogRef.afterClosed();
  }

  openErrorDialog(title, message, okText): Observable<any> {
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      minWidth: '10vw',
      maxWidth: '350px',
      autoFocus: true,
      data: { title, message, okText }
    });
    return dialogRef.afterClosed();
  }

  openVoucherCodesEdit(title, message, okText, eventID): Observable<any> {
    const dialogRef = this.dialog.open(EditVoucherCodesDialogComponent, {
      minWidth: '30vw',
      maxWidth: '50vw',
      data: { title, message, okText, eventID }
    });

    return dialogRef.afterClosed();
  }

  openUsedVoucherCodes(title, message, okText, eventID): Observable<any> {
    const dialogRef = this.dialog.open(ShowVoucherCodesComponent, {
      minWidth: '30vw',
      maxWidth: '50vw',
      data: { title, message, okText, eventID }
    });

    return dialogRef.afterClosed();
  }

  openBottomSheet(event: EventResource): Observable<any> {
    const dialogRef = this._bottomSheet.open(ChangeEventStateComponent, {
      data: { event }
    });
    return dialogRef.afterDismissed();
  }

  openBottomSheetBusinessEntityResourceState(businessEntityResource: BusinessEntityResource): Observable<any> {
    const dialogRef = this._bottomSheet.open(ChangeBusinessEntityStateComponent, {
      data: { businessEntityResource }
    });
    return dialogRef.afterDismissed();
  }

  openBottomSheetBusinessUserResourceState(businessUserResource: BusinessUserResource): Observable<any> {
    const dialogRef = this._bottomSheet.open(ChangeBusinessUserStateComponent, {
      data: { businessUserResource }
    });
    return dialogRef.afterDismissed();
  }

  openBottomSheetBusinessUserResourceRole(businessUserResource: BusinessUserResource): Observable<any> {
    const dialogRef = this._bottomSheet.open(ChangeBusinessUserRoleComponent, {
      data: { businessUserResource }
    });
    return dialogRef.afterDismissed();
  }


  openAddHouseholdMemberDialog(title, message, okText, head: BusinessUserResource): Observable<any> {
    const dialogRef = this.dialog.open(AddHouseHoldmemberComponent, {
      minWidth: '30vw',
      maxWidth: '350px',
      autoFocus: true,
      data: { title, message, okText, head }
    });
    return dialogRef.afterClosed();
  }

  openResetPWUserDialog(title, message, okText, user: BusinessUserResource): Observable<any> {
    const dialogRef = this.dialog.open(ResetPWForUserComponent, {
      minWidth: '30vw',
      maxWidth: '350px',
      autoFocus: true,
      data: { title, message, okText, user }
    });
    return dialogRef.afterClosed();
  }



  openQrCodeDialog(title, message, okText, event: EventResource): Observable<any> {
    const dialogRef = this.dialog.open(ShowQrCodeComponent, {
      minWidth: '30vw',
      maxWidth: '350px',
      autoFocus: true,
      data: { title, message, okText, event }
    });
    return dialogRef.afterClosed();
  }

  openCardQrCodeDialog(title, okText, card: CardResource): Observable<any> {
    const dialogRef = this.dialog.open(ShowCardQrCodeComponent, {
      minWidth: '30vw',
      maxWidth: '350px',
      autoFocus: true,
      data: { title, okText, card }
    });
    return dialogRef.afterClosed();
  }

  openDeleteInterptorDialog(title, message, okText, cancelText): Observable<any> {
    const dialogRef = this.dialog.open(DeleteComponent, {
      minWidth: '30vw',
      maxWidth: '350px',
      autoFocus: true,
      data: { title, message, okText, cancelText }
    });
    return dialogRef.afterClosed();

  }

  openAutoUpdateEventStatusComponent(title, message, okText, cancelText): Observable<any> {
    const dialogRef = this.dialog.open(AutoUpdateEventStatusComponent, {
      minWidth: '30vw',
      maxWidth: '350px',
      autoFocus: true,
      data: { title, message, okText, cancelText }
    });
    return dialogRef.afterClosed();

  }
}