export const SBModulesList = [{
   label: 'Kunden',
   children: [{
     label: 'Übersicht',
     icon: 'dashboard',
     path: 'dashboard',
     queryParams: { filterByCustomerType: 'MAINCUSTOMER,SUBCUSTOMER', filterByState: 'ACTIVE,PENDING' ,page : 0, size : '36'}
   },
   {
     label: 'Aufgaben',
     icon: 'assignment',
     path: 'task',
     disabled: false
   },
  //  {
  //    label: 'Clubstatus',
  //    icon: 'stars',
  //    path: 'clubstate',
  //    disabled: false
  //  },
 ]
 }, 
 {
   label: 'Aktionen',
   children: [{
     label: 'Übersicht',
     icon: 'dashboard',
     path: 'event',
     queryParams: { filterDiscountType: 'SERVICE_PLUS,MONETARY', filterState: 'DRAFT,ACTIVE,PREVIEW' }
   },
   {
     label: 'Freigaben',
     icon: 'new_releases',
     path: 'release'
   },
   {
      label:"Verlosungen",
      icon:"gavel",
      path: 'event',
      queryParams: { filterDiscountType: 'RAFFLE', filterState: 'DRAFT,ACTIVE,PREVIEW' }
   },
   {
      label:"Positionen",
      icon:"view_headline",
      path: 'position',
      queryParams: {}
   }]
 },
 {
   label: 'Partner',
   children: [
     {
       label: 'Übersicht',
       icon: 'contacts',
       path: 'businessentity',
       disabled: false
     }]
 },
 {
   label: 'Reports',
   children: [{
     label: 'Aktionen Report',
     icon: 'data_usage',
     path: 'report',
     disabled: false
   },
   // {
   //   label: 'Nutzerdaten Report',
   //   icon: 'supervised_user_circle',
   //   path: 'userreport',
   //   disabled: true
   // },
   {
     label: 'Stammdaten Report',
     icon: 'supervised_user_circle',
     path: 'basedatareport',
     disabled: false
   }
   ]
 },
 {
   label: 'Gutscheine',
   children: [{
     label: 'Übersicht',
     icon: 'dashboard',
     path: 'reservation',
     disabled: false,
     queryParams: { filterByStates: 'RESERVED,USED' }
   },
   {
     label: 'Veranstaltungsscanner',
     icon: 'scanner',
     path: 'smartquick',
     disabled: false
   },
   {
     label: 'Import Teilnahmen',
     icon: 'cloud_upload',
     path: 'bulk',
     disabled: false
   }
 ]
 },
 {
   label: 'Inhalte',
   children: [{
     label: 'Interessen',
     icon: 'ballot',
     path: 'interest',
     disabled: false
   },
   {
     label: 'FAQs',
     icon: 'question_answer',
     path: 'faq',
     disabled: false
   },
   // {
   //   label: 'Infothek',
   //   icon: 'dynamic_feed ',
   //   path: 'news',
   //   disabled: false,
   //   queryParams: { filterByStates: 'DRAFT,PUBLISHED', filterByTypes: 'NEWS,APPOINTMENT', filterByVisibility: 'PUBLIC,PRIVATE' }
   // },
   // {
   //   label: 'RSS-Feed',
   //   icon: 'rss_feed ',
   //   path: 'rssFeed',
   //   disabled: false
   // }
 ]
 },
 {
   label: 'Kommunikation',
   children: [{
     label: 'Push-Nachrichten',
     icon: 'phonelink_ring',
     path: 'pushmessages',
     disabled: false
   },
   {
     label: 'Kampagnen',
     icon: 'scanner',
     path: 'component',
     disabled: false
   },
   {
     label: 'Werbeboxen',
     icon: 'comment',
     path: 'advertisingbox',
     disabled: false
   },
  //  {
  //    label: 'Adventskalender',
  //    icon: 'calendar_today',
  //    path: 'advent',
  //    disabled: false
  //  }
 ]
 }];
 
