import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateFilterCard'
})
export class TranslateFilterCardPipe implements PipeTransform {

  transform(value: string, type: String): string {
    if (type == 'filterState') {
      switch (value) {
        case 'CREATED':
          return "Erstellt";
        case 'AVAILABLE':
          return "Verfügbar";
        case 'REQUESTED':
          return "Angefordert";
        case 'ASSIGNED':
          return "Zugeordnet";
        case 'ACTIVE':
          return "Aktiv";
        case 'CLOSE_REQUEST':
          return "Neuversand";
        case 'CLOSED':
          return "Gesperrt";
        default:
          return value;
      }
    }
  }
}
