import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'anonymize'
})
export class AnonymizePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    return value.substring(0,5) + Array(value.length-4).join("*") +" - "+ (args[0]['id']);
  }

}
