import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'permissionDisplayName'
})
export class PermissionDisplayNamePipe implements PipeTransform {

  transform(permission: any, ...args: any[]): any {
    let result = '';

    switch (permission) {
      case 'ROLE_ANON': result = 'Anonymous'; break;
      case 'ROLE_PRE_CUSTOMER': result = 'Vorkunde'; break;
      case 'ROLE_CUSTOMER': result = 'Kunde/Mitarbeiter'; break;
      case 'ROLE_CASEWORKER': result = 'Sachbearbeiter'; break;
      case 'ROLE_PARTNER': result = 'Partner'; break;
      case 'ROLE_ADMIN': result = 'Admin'; break;
      
      default: result = permission;
      }
      
      return result;
  }

}
