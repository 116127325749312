

<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content >
    <div class="QRContainer">
        <img class="qr" [src]="qr">
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-button mat-raised-button color="primary" (click)="cancel()">{{ data.okText }}</button>
</div>