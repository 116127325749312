import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serviceFilter'
})
export class ServiceFilterPipe implements PipeTransform {

  transform(services: any[], filter: any): any {
    let result = [];
    console.log('services: ', services);
    console.log('filter: ', filter);
    
    if (!filter) return services;

    const roles = filter.filterByRoles;
    const states = filter.filterByStates.map(val => {
      if (val == 'ACTIVE') return true;
      else if (val == 'DISABLED') return false;
    });
    const modules = filter.filterByModules;
    const term = filter.searchTerm;

    
    for (let service of services) {
      let check = true;
      
      if (roles.indexOf(service.permission) < 0) {
        check = false;
      }

      if (term != '' && (service.name.toLowerCase().indexOf(term) < 0 && service.path.toLowerCase().indexOf(term) < 0)) {
        check = false;
      }

      if (states.length > 0 && states.indexOf(service.active) < 0) {
        check = false;
      }

      if (modules.length > 0 && modules.indexOf(service.path) < 0) {
        let isModule = true;

        for (let module of modules) {
          if (service.path.indexOf(module) < 0) {
            isModule = false;
            break;
          }
        }
        if (!isModule) {
          check = false;
        }
      }

      if (roles.indexOf(service.permission) < 0) {
        check = false;
      }

      if (check) {
        result.push(service);
      }
    }
    console.log(result)
    return result;
  }

}
