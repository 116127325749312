import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessUserResource } from 'src/app/interfaces/business-user-resource';
import { BusinessEntityService } from 'src/app/services/business-entity.service';
import { ContractService } from 'src/app/services/contract.service';

export interface DialogData {
  title: string;
  message: string;
  okText: string;
  cancelText: string;
  object;
  type: string;
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private businessEntityService: BusinessEntityService,
    private contractService: ContractService
  ) { }

  ngOnInit() {
  }

  delete() {
    switch (this.data.type) {
      case 'channel':
        this.businessEntityService.delteContactChannel(this.data.object.currentBusinessEntity, this.data.object.channel).subscribe(value => {
          console.log("DELETE CHANNEL", value)
        })
        break;
      case 'members':
        this.businessEntityService.deleteMember(this.data.object).subscribe(deleteRes => {
          console.log("DELTE :", deleteRes);
        })
        break;
      case 'openingHours':

        break;
      case 'boxes':
        let all = [];
        all = this.data.object.all;
        let toDelete = this.data.object.toDelete;
        all.forEach((element, index) => {
          if (element[0] == toDelete[0]) {
            all.splice(index, 1);
          }
        });
        this.contractService.updateComponent_web_intro_landing_page(JSON.stringify(all)).subscribe(deleteRes => {
          console.log("DELTE :", deleteRes);
        });
        break;
      default:
        break;
    }
  }
  cancel(): void {
    this.dialogRef.close();
  }

}
