<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content [formGroup]="pwForm">
    <div class="row flex">
        <div class="col-sm-12">
            <mat-form-field class="full-width">
                <mat-label>Neues Passwort:</mat-label>
                <input matInput formControlName="newPassword" [type]="hidePW ? 'password' : 'text'">
                <mat-icon matSuffix (click)="hidePW = !hidePW">{{hidePW ? 'visibility_off' : 'visibility'}}</mat-icon>
                
                <mat-error *ngIf="pwForm.get('newPassword').hasError('required')">
                    Passwort <strong>muss</strong> angegeben werden
                </mat-error>
                <mat-error  *ngIf="pwForm.get('newPassword').hasError('minlength')"> 
                    Passwort <strong>muss</strong> 6 Zeichen lang sein
                </mat-error>

            </mat-form-field>
        </div>
    </div>

    <div class="row flex">
        <div class="col-sm-12">
            <mat-form-field class="full-width">
                <mat-label>Passwort wiederholen:</mat-label>
                <input matInput formControlName="passwordRecitation" [type]="hidePWConfirm ? 'password' : 'text'">
                <mat-icon matSuffix (click)="hidePWConfirm = !hidePWConfirm">{{hidePWConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error *ngIf="pwForm.get('passwordRecitation').hasError('required')">
                    Passwort <strong>muss</strong> angegeben werden
                </mat-error>
                <mat-error  *ngIf="pwForm.get('passwordRecitation').hasError('minlength')"> 
                    Passwort <strong>muss</strong> 6 Zeichen lang sein
                </mat-error>
                <mat-error  *ngIf="pwForm.get('passwordRecitation').hasError('confirmedValidator')"> 
                    Passwörter <strong>müssen</strong> gleich sein
                </mat-error>        
            </mat-form-field>
        </div>
    </div>
</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-button mat-raised-button color="primary" (click)="setPW()">{{ data.okText }}</button>
</div>

<!-- 
*:
Bitte vergeben Sie ein neues Passwort.*: -->