<div class="sticky-header">
    <mat-toolbar color="accent">
      <button mat-icon-button (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button>
      <a>{{title}}</a>
        <span fxFlex></span>
        <a routerLink="" class="logoutHeader" mat-button (click)="logout()">
          Abmelden
        </a>
        <!-- <button mat-icon-button class="filterMobileIcon" (click)="filternav.toggle()"><mat-icon>filter_alt</mat-icon></button> -->
      </mat-toolbar>
</div>