import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { Router, Event, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from 'src/app/services/sidenav.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @Input() sidenav: MatSidenav;
  @Input() filternav: MatSidenav;
  title:string = environment.appName;
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger;
  modulesList: Array<any>;
  modulesListAPI: Array<any>;
  menuOpen = [false, false];
  activeHeader = [false, false];

  breadcrumbs$: Observable<any[]>; 
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private ren: Renderer2,
    private sidenavService: SidenavService) {
    this.router.events.subscribe((e: Event) => {
      if (e instanceof NavigationEnd) {
        const path = e.url.split('/').pop();
        const firstActive = path == 'businessuser' || path == 'client';
        const secondActive = path == 'import' || path == 'export' || path == 'processed' || path == 'garbage';
        this.activeHeader = [firstActive, secondActive];
      }
    });
   
  }

  ngOnInit() {
  }




  logout() {
    this.authenticationService.logout();
    this.router.navigateByUrl('/');
  }

  menuenter(index) {
    this.menuOpen = [index == 0, index == 1];
    this.isMatMenuOpen = true;
  }

  menuLeave(trigger, button, index) {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.isMatMenuOpen = false;
      }

      this.menuOpen = [false, false];

    }, 80)
  }

  buttonEnter(trigger, index) {
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
      }
      else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
        trigger.openMenu()
      }
      else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
      }

      this.menuOpen = [index == 0, index == 1];
    })
  }

  buttonLeave(trigger, button) {
    this.menuOpen = [false, false];

    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.enteredButton = false;
      }

    }, 100)
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.filternav);
  }

}
