import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CardResource } from '../interfaces/card-resouce';
import { PagedResources } from '../interfaces/paged-resources';
import { BusinessuserService } from './businessuser.service';
import { CustomerService } from './customer.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  constructor(
    private http: HttpClient,
    private environment: EnvironmentService) {

  }

  getCards(page, size): Observable<PagedResources<CardResource>> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      "filterByState": ["ACTIVE"]
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post<PagedResources<CardResource>>(`${url}/card/search`, body, { params })
      )
    )
  }


  seachCards(page, size, filterByState, searchTerm): Observable<PagedResources<CardResource>> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByState: filterByState,
      searchTerm: searchTerm
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post<PagedResources<CardResource>>(`${url}/card/search`, body, { params })
      )
    )
  }

  getCard(cardID: string) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/card/${cardID}`)
      )
    )
  }

  createCard(card) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/card`, card)
      )
    )
  }

  editCard(card) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/card/${card.publicId}`, card)
      )
    )
  }

  getCardQRCode(card) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/card/${card.publicId}/qr`, { responseType: 'blob' })
      )
    )
  }


  deleteCard(card) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.delete(`${url}/card/${card.publicId}`)
      )
    )
  }


  getHistory(cardID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/card/${cardID}/history`)
      )
    )
  }

  getPDF(cardID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/card/${cardID}/regenerate`,{responseType : 'blob'})
      )
    )
  }

  getNotiPDF(cardID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/card/${cardID}/notification`,{responseType : 'blob'})
      )
    )
  }
}
