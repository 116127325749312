export const environment = {
  'production': true,
  'proxy': 'https://proxy-dev.swaas.de',
  'mcp': 'https://mcp.swaas.de/',
  'MCP_CLIENTS_JSON': 'https://mcp-dev.swaas.de/clients.json',
  'clientID': 103,
  'apiURL': 'https://stadtwerke-gronau.plusservices.de',
  'appVersion': require('../../package.json').version,
  'ngVersion': require('../../package.json').dependencies['@angular/pwa'],
  'appName' : 'Stadtwerke Gronau Dashboard'
}
