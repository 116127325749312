import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { State } from 'src/app/enums/state.enum';
import { EventResource } from 'src/app/interfaces/event-resource';
import { Interest } from 'src/app/interfaces/interest';
import { EventService } from 'src/app/services/event.service';
import { OverlayService } from 'src/app/services/overlay.service';

export interface DialogData {
  title: string;
  message: string;
  okText: string;
  cancelText: String;
  event: EventResource;
}

@Component({
  selector: 'app-add-categories',
  templateUrl: './add-categories.component.html',
  styleUrls: ['./add-categories.component.scss']
})
export class AddCategoriesComponent implements OnInit {
  interestForm: UntypedFormGroup;
  interestCheckboxOptions: Array<any> = []
  interests: Array<Interest> = []
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddCategoriesComponent>,
    private fb: FormBuilder,
    private eventService: EventService,
    private overlayService: OverlayService
  ) {

    this.interestForm = this.fb.group({
      interests: this.fb.array([])
    })



  }


  addInterestToEvent() {
    let arr: Array<Observable<any>> = [];
    this.interestForm.value.interests.forEach(element => {
      let tempInteres: Interest = {
        'name': element.name,
        'publicId': element.value
      }
      arr.push(this.eventService.addInterestToEvent(this.data.event.publicId, tempInteres))
    });

    if (arr.length != 0) {
      forkJoin(arr).subscribe(() => {
        this.data.event.state = State.ACTIVE;
        this.eventService.changeState(this.data.event).subscribe(change => {
          this.overlayService.openSnackBar('Aktion wurde erfolgreich aktiviert!');
        })
      })
    } else {
      this.data.event.state = State.ACTIVE;
      this.eventService.changeState(this.data.event).subscribe(change => {
        this.overlayService.openSnackBar('Aktion wurde erfolgreich aktiviert!');
      })
    }
  }


  ngOnInit(): void {
    this.eventService.getInterests().subscribe((interests: any) => {
      this.interests =  interests;
      this.interests.forEach(interest => {
        this.interestCheckboxOptions.push({ name: interest.name, value: interest.publicId, checked: false })
      });
      this.data.event.interests.forEach(element => {
        this.interestCheckboxOptions.forEach((option) => {
          if (element.name == option.name) {
            option.checked = true;
          }
        });
      });
    })
  }

  cancel(): void {
    this.dialogRef.close();
  }

  onCheckboxChange(e) {
    const checkArray: UntypedFormArray = this.interestForm.get('interests') as UntypedFormArray;
    if (e.checked) {
      checkArray.push(new UntypedFormControl(e.source.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: UntypedFormControl) => {
        if (item.value == e.source.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
}
