import { MatPaginatorIntl } from '@angular/material/paginator';

export function getGermanPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();
    paginatorIntl.itemsPerPageLabel = 'Einträge pro Seite:';
    paginatorIntl.nextPageLabel = 'Nächste Seite';
    paginatorIntl.previousPageLabel = 'Vorherige Seite';
    paginatorIntl.lastPageLabel = 'Letzte Seite';
    paginatorIntl.firstPageLabel = 'Erste Seite';
    return paginatorIntl;
}
