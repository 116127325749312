import { Component, OnInit } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { ContractService } from './services/contract.service';
import { LoadingService } from './services/loading.service';
import { McpService } from './services/mcp.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'plusservices-angular';
  showLoading: boolean = true;
  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private mcpService:McpService) {
  }
  ngOnInit() {
    this.loadingService.loading$.subscribe(loading => {
      this.showLoading = loading;
    })



    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loadingService.show();
          break;
        } case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loadingService.hide();
          break;
        }
        default: {
          break;
        }
      }
    });
  }
}
