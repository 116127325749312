import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateFilterContract'
})
export class TranslateFilterContractPipe implements PipeTransform {

  transform(value: string[], type: String): string[] {
    if(value){
      if (type == 'filterByStates') {
        let returnArray = [];
        value.forEach((element) => {
          switch (element) {
            case 'NEW':
              if (returnArray.length == 0) {
                returnArray.push("Neu");
              }
              break;
            case 'RUNNING':
              if (returnArray.length == 0) {
                returnArray.push("Läuft");
              }
              break;
            case 'CANCELLED':
              if (returnArray.length == 0) {
                returnArray.push("Gekündigt");
              }
              break;
            case 'FINISHED':
              if (returnArray.length == 0) {
                returnArray.push("Beendet");
              }
              break;
            case 'DELETED':
              if (returnArray.length == 0) {
                returnArray.push("Gelöscht");
              }
              break;
            default:
              returnArray.push(element);
              break;
          }
        });
        return returnArray
      } else if (type == 'filterByTypes') {
        let returnArray = [];
        value.forEach((element) => {
          switch (element) {
            case 'UNKNOWN':
              if (returnArray.length == 0) {
                returnArray.push("unbekannt");
              }
              break;
            case 'ACCOUNT':
              if (returnArray.length == 0) {
                returnArray.push("Vertragskonto");
              }
              break;
            case 'POWER':
              if (returnArray.length == 0) {
                returnArray.push("Strom");
              }
              break;
            case 'GAS':
              if (returnArray.length == 0) {
                returnArray.push("Gas");
              }
              break;
            case 'WATER':
              if (returnArray.length == 0) {
                returnArray.push("Wasser");
              }
              break;
            case 'CABLE':
              if (returnArray.length == 0) {
                returnArray.push("Kabel");
              }
              break;
            case 'MOBILE':
              if (returnArray.length == 0) {
                returnArray.push("Mobil");
              }
              break;
            case 'HEAT':
              if (returnArray.length == 0) {
                returnArray.push("Wärme");
              }
              break;
            case 'PHOTOVOLTAIK':
              if (returnArray.length == 0) {
                returnArray.push("Photovoltaik");
              }
              break;
            case 'FIBER':
              if (returnArray.length == 0) {
                returnArray.push("Glasfaser");
              }
              break;
            case 'GARDENWATER':
              if (returnArray.length == 0) {
                returnArray.push("Gartenwasser");
              }
              break;
            default:
              returnArray.push(element);
              break;
          }
        });
        return returnArray
      }
    }
   

  }
}
