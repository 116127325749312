import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PropertyService } from 'src/app/services/property.service';

@Component({
  selector: 'app-mandant-logo',
  templateUrl: './mandant-logo.component.html',
  styleUrls: ['./mandant-logo.component.scss'],
})
export class MandantLogoComponent implements OnInit {

  logo$: Subject<string> = new Subject()

  constructor(
    private propertyService: PropertyService,
  ) { }

  ngOnInit() {
    this.logo$ = this.propertyService.logo$
  }
}
