import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { combineLatest, forkJoin, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { groupBy, map, mergeMap, reduce, switchMap, toArray } from 'rxjs/operators';
import { EventResource } from '../interfaces/event-resource';
import { Interest } from '../interfaces/interest'
import { UserReservations } from '../interfaces/user-reservation';
import { PagedResources } from '../interfaces/paged-resources';
import { EventReservationRequest } from '../interfaces/event-reservation-request';
import { BusinessUserResource } from '../interfaces/business-user-resource';
import { BusinessuserService } from './businessuser.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  eventCount$: Subject<number> = new ReplaySubject(1)
  alleventCount$: Subject<number> = new ReplaySubject(1)
  constructor(
    private http: HttpClient,
    private environment: EnvironmentService,
    private businessuserService: BusinessuserService) {
    this.searchForEvents(0, 1, ['ACTIVE', 'HIDDEN'], null, null, null).subscribe((count: PagedResources<EventResource>) => {
      this.eventCount$.next(count.page.totalElements)
    })

    this.searchForEvents(0, 999, ['ARCHIVED', 'EXPIRED'], null, null, null).subscribe((count: PagedResources<EventResource>) => {
      this.alleventCount$.next(count.page.totalElements)
    })



  }


  getEvent(publicid: string): Observable<any> {
    return this.environment.apiV2$.pipe(
      switchMap(url => this.http.get(`${url}/event/${publicid}`)
      )
    )
  }
  getEventV1(publicid: string): Observable<any> {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event/${publicid}`)
      )
    )
  }


  getEvents(page: number, size: number): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event`, { params }))
    )
  }



  disableEvent(event) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/event`, event))
    )
  }



  mergeEventsFromV1andV2(page: number, size: number, state, type, partners, term) {
    return combineLatest([
      this.searchForEvents(page, size, state, type, partners, term),
      this.searchForEventsV2(page, size, state, type, partners, term),
    ]).pipe(
      map(([v1, v2]) => {
        let l = {
          page: v1['page'],
          content: v1['content'].map((item, i) => Object.assign({}, item, v2['content'][i])),
          links: v1['links'],
        }
        return l;
      })
    )
  }

  mergeOneEventFromV1andV2(publicid: string): Observable<any> {
    return combineLatest([
      this.getEvent(publicid),
      this.getEventV1(publicid),
    ]).pipe(
      map(([v1, v2]) => {
        return Object.assign(v1, v2);
      })
    )
  }

  searchForEvents(page: number, size: number, state, type, partners, term) {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByStates: state,
      filterByDiscountType: type,
      filterByPartners: partners,
      searchTerm: term
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/search`, body, { params }))
    )
  }

  searchForEventsV2(page: number, size: number, state, type, partners, term) {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByStates: state,
      filterByDiscountType: type,
      filterByPartners: partners,
      searchTerm: term
    }
    return this.environment.apiV2$.pipe(
      switchMap(url => this.http.post(`${url}/event/search`, body, { params }))
    )
  }

  getInterests() {
    const body = {
      filterByState: "ACTIVE"
    }

    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/interest/search`, body, {}).pipe(

      ))
    )

  }

  getPartners() {
    return this.http
      .get<any>(`${this.environment.getApiUrl()}/event/partner?page=${0}&size=${999}`, {});
  }

  createEvent(event: EventResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event`, event))
    )
  }

  editEvent(event: EventResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/event`, event)
      )
    )
  }

  addInterestToEvent(eventID, interest: Interest) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/${eventID}/interest`, interest, {}))
    )
  }

  removeInterestFromEvent(eventID, interestID: number) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.delete(`${url}/event/${eventID}/interest/${interestID}`, {})
      )
    )
  }

  getInterestsForEvent(eventID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event/${eventID}/interest`, {})
      )
    )
  }


  changeState(event) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/event`, event))
    )
  }

  getTopThreeEvents(state, type, partners, term) {
    const params = new HttpParams({
      fromObject: {
        page: 0,
        size: 999
      }
    })

    const body = {
      filterByStates: state,
      filterByDiscountType: type,
      filterByPartners: partners,
      searchTerm: term
    }

    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/search`, body, { params }).pipe(
        map((res: any) => {
          let records = res.content;
          return records.sort((a, b) => {
            return b.totalActiveReservationsCount - a.totalActiveReservationsCount;
          });
        })
      )
      )
    )
  }





  getReservationCountToEventID(page, size, state, eventPublicId): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByStates: state,
      eventPublicId: eventPublicId
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/attendance/search`, body, { params }))
    )
  }


  getAllReservationsToEventID(page, size, state, eventPublicId): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByStates: state,
      eventPublicId: eventPublicId
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/attendance/search`, body, { params }).pipe(
        map(((rallies: any) => rallies.content.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())))
      ))
    )
  }


  getEventsForCampaigns(page, size) {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByStates: ["HIDDEN"],

    }
    return this.environment.apiV2$.pipe(
      switchMap(url => this.http.post(`${url}/event/search`, body, { params })
      )
    )
  }


  searchForEventReservations(page: number, size: number, sort, state, paymentState, partners, term, event, eventStates, customerPublicIds?) {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size,
        sort: sort
      }
    })
    const body = {
      filterByStates: state,
      filterByPaymentStates: paymentState,
      eventPartnerPublicIds: partners,
      searchTerm: term,
      eventPublicId: event,
      eventStates: eventStates,
      customerPublicIds: customerPublicIds
    }
    return this.environment.apiV2$.pipe(
      switchMap(url => this.http.post(`${url}/event/reservation/search`, body, { params }))
    )
  }

  searchForEventReservationsV1(page: number, size: number, sort, state, paymentState, partners, term, event, eventStates, customerPublicIds?) {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size,
        sort: sort
      }
    })
    const body = {
      filterByStates: state,
      filterByPaymentStates: paymentState,
      eventPartnerPublicIds: partners,
      searchTerm: term,
      eventPublicId: event,
      eventStates: eventStates,
      customerPublicIds: customerPublicIds
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/reservation/search`, body, { params }))
    )
  }

  getReservationListForEvent(page: number, size: number, eventID) {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size,
      }
    })
    const body = {
      eventPublicId: eventID
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/reservation/search`, body, { params }))
    )

  }


  getReservationListForEventGrouped(eventID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event/${eventID}/reservation/grouped`))
    )
  }


  getReservationListForEventGroupBy(page: number, size: number, eventID, filterByStates): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size,
      }
    })
    const body = {
      eventPublicId: eventID,
      filterByStates: filterByStates
    }

    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/reservation/search`, body, { params }))
    )
  }

  getPDF(reservationID) {

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');



    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/reservation/${reservationID}/voucher/pdf`, { headers: headers, responseType: 'blob' }))
    )
  }

  delteReservation(resID) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    const body = '"CANCELLED"'
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/reservation/${resID}/state`, body, options))
    )
  }

  delteAlreadyUsesReservation(resID) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    const body = '"RESERVED"'
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/reservation/${resID}/state`, body, options))
    )
  }

  useReservation(resID) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    const body = '"USED"'
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/reservation/${resID}/state`, body, options))
    )
  }

  createReservation(user: BusinessUserResource, event: EventResource, emailNotify, force) {
    const body: EventReservationRequest = {
      customerPublicId: user.publicId,
      eventPublicId: event.publicId,
      force: force
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/reservation/check-and-reserve?emailNotify=${emailNotify}`, body))
    )
  }

  quickAttendance(eventID: String, code: String, forceAttendance) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    const body = code;
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/${eventID}/quickAttendance?forceAttendance=${forceAttendance}`, body, options))
    )
  }


  buldUpdateEvents(dryrun) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/bulkupdate?dryRun=${dryrun}`, {}))
    )
  }

  getQRCodeAsPNG(event: EventResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event/${event.publicId}/qr`, { responseType: 'blob' })
      )
    )
  }


  downloadReservationList(event) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event/${event.publicId}/reservation`, { responseType: 'blob', headers: new HttpHeaders().set('Content-Type', 'application/vnd.ms-excel') })
      )
    )
  }

  loadAdvancedEventProperties(eventID:string){
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event/${eventID}/advanced`)
      )
    )
  }

  saveAdvancedEventProperties(event:EventResource){
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/event/${event.publicId}/advanced`, event)
      )
    )
  }
}
