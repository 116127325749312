import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, map } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { McpService } from '../services/mcp.service';

@Injectable({
  providedIn: 'root'
})
export class RoleCaseworkerGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private mcp: McpService
  ) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.decodedToken$.value;
    if (currentUser.role == "ROLE_CASEWORKER") {
      // logged in so return true
      return true;
    }


    // not logged in so redirect to login page with the return url
    this.router.navigate(['/']);
    return false;
  }
}
