import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateFilterTicket'
})
export class TranslateFilterTicketPipe implements PipeTransform {

  transform(value: string[], type: string): string[] {
    if (type == 'filterByTicketStates') {
      let returnArray = [];
      value.forEach((element) => {
        switch (element) {
          case 'NEW':
            if (returnArray.length == 0) {
              returnArray.push("Neu");
            }
            break;
          case 'PARKED':
            if (returnArray.length == 0) {
              returnArray.push("Zurückgestellt");
            }
            break;
          case 'CLOSED':
            if (returnArray.length == 0) {
              returnArray.push("Geschlossen");
            }
            break;
            default:
              returnArray.push(element);
          // case 'DELETED':
          //   if (returnArray.length == 0) {
          //     returnArray.push("Aufgaben mit unbekanntem Kunden");
          //   }
          //   break;
        }
      })
      return returnArray
    } else if (type == 'filterByIssueTypes') {
      let returnArray = [];



      value.forEach((element) => {
        switch (element) {
          case 'BASIC':
            if (returnArray.length == 0) {
              returnArray.push("nicht definiert");
            }
            break;
          case 'SIGN_UP':
            if (returnArray.length == 0) {
              returnArray.push("Kunde werden o. Tarif wechseln");
            }
            break;
          case 'MEASURE':
            if (returnArray.length == 0) {
              returnArray.push("Zählerstand");
            }
            break;
          case 'PART_PAYMENT':
            if (returnArray.length == 0) {
              returnArray.push("Abschlagsänderung");
            }
            break;
          case 'BANK_DETAILS':
            if (returnArray.length == 0) {
              returnArray.push("Bankdatenänderung");
            }
            break;
          case 'RELOCATION_V1':
            if (returnArray.length == 0) {
              returnArray.push("Ummeldung");
            }
            break;
          case 'RELOCATION_V2':
            if (returnArray.length == 0) {
              returnArray.push("Ummeldung");
            }
            break;
          case 'MASTERDATA':
            if (returnArray.length == 0) {
              returnArray.push("Stammdatenänderung");
            }
            break;
          case 'PHOTOVOLTAICS':
            if (returnArray.length == 0) {
              returnArray.push("Photovoltaik");
            }
            break;
          case 'PRINT_CARD':
            if (returnArray.length == 0) {
              returnArray.push("Kartendruck");
            }
            break;
          case 'PROSPECT':
            if (returnArray.length == 0) {
              returnArray.push("Wechselprozess");
            }
            break;
          case 'INQUIRY':
            if (returnArray.length == 0) {
              returnArray.push("Allgemeine Anfrage");
            }
            break;
            default:
              returnArray.push(element);
        }
      })
      return returnArray
    }
  }

}
