import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateFilterServiceModule'
})
export class TranslateFilterServiceModulePipe implements PipeTransform {



  transform(value: string[]): string[] {
    let returnArray = [];
    value.forEach((element, index) => {
      switch (element) {
        case 'event':
          if (returnArray.length == 0) {
            returnArray.push("Aktionswelt");
          }
          break;
        case 'news':
          if (returnArray.length == 0) {
            returnArray.push("Infothek");
          }
          break;
        case 'card':
          if (returnArray.length == 0) {
            returnArray.push("Karte");
          }
          break;
        default:
          returnArray.push(element);
          break;
      }
    });
    return returnArray

  }
}
