<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content>
    <div class="container">
        <div class="row" [formGroup]="interestForm">
            <h5>Falls die Aktion von einem Partner angelegt wurde, können Sie dieser nun Kategorien hinzufügen.</h5>
            <div class="col-sm-12 marginTop2Rem">
                <div class="row" formGroupName="interests">
                    <div class="col-lg-6 col-sm-12" *ngFor="let interestCheckbox of interestCheckboxOptions; let i=index">
                        <label>
                            <mat-checkbox class="example-margin" [value]="interestCheckbox" [checked]="interestCheckbox.checked" [disabled]="interestCheckbox.checked"
                                (change)="onCheckboxChange($event)">
                                {{interestCheckbox.name}}
                            </mat-checkbox>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">{{data.cancelText}}</button>
    <button mat-button mat-raised-button color="primary" [mat-dialog-close]="true" (click)="addInterestToEvent()" cdkFocusInitial>{{ data.okText
        }}</button>
</div>