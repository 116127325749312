import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { McpService } from 'src/app/services/mcp.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})
export class Page404Component implements OnInit {
  title:string = environment.appName;
  constructor(
    private router: Router,
    private mcp: McpService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {

  }

  moveToStart() {
    const config = JSON.parse(localStorage.getItem('config'))
    this.mcp.loadConfigurationWithID(config.id).subscribe((val) => {
      if (this.authenticationService.isSuperAdmin()) {
        this.router.navigateByUrl('/admin/client-list');
      } else if (this.authenticationService.isAdmin()) {
        this.router.navigateByUrl('/admin');
      } else if (this.authenticationService.isSB()) {
        console.log(val)
        if (val.SB_MENU) {
          if (val.SB_MENU[0].label == "Reports") {
            this.router.navigateByUrl('/caseworker/report');
          }else{
            this.router.navigateByUrl('/caseworker');
          }
        } else {
          this.router.navigateByUrl('/caseworker');
        }
      } else if (this.authenticationService.isPartner()) {
        this.router.navigateByUrl('/partner');
      }
    })
  }
}
