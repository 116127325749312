<mat-toolbar color="accent" class="mat-elevation-z6 fixed-header">
    <a>{{title}}</a>
    <span fxFlex></span>
</mat-toolbar>
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <div class="wrapper404">
                <div style="text-align: center;">
                    <h1>Fehler 404</h1>
                    <h5>Die Angegebene Seite konnte nicht gefunden werden</h5>
                    <button mat-raised-button color="primary" (click)="moveToStart()">Zurück zum Start</button>
                </div>
            </div>
        </div>
    </div>
</div>

