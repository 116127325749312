import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VoucherService } from 'src/app/services/voucher.service';

interface DialogData {
  title: string;
  message: string;
  okText: string;
  eventID: string;
}


@Component({
  selector: 'app-show-voucher-codes',
  templateUrl: './show-voucher-codes.component.html',
  styleUrls: ['./show-voucher-codes.component.scss']
})
export class ShowVoucherCodesComponent implements OnInit {
  usedVouchers = [];
  constructor(
    public dialogRef: MatDialogRef<ShowVoucherCodesComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private voucherService:VoucherService) {}

  ngOnInit() {
    this.voucherService.getAllVoucherCodesToEvent(this.data.eventID).subscribe(value => {
      value.externalVoucherKeys.forEach(element => {
        if (element.usedAt != null) {
          this.usedVouchers.push(element)
        }
      });
    })
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
