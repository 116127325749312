<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content>


    <div class="row flex" style="margin-top: 0.5rem;" [formGroup]="membersForm">
        <div class="col-sm-12">
            <div formGroupName="person">
                <div class="row flex">
                    <div class="col-sm-4">
                        <mat-form-field class="full-width">
                            <mat-label>Anrede:</mat-label>
                            <mat-select formControlName="gender">
                                <mat-option *ngFor="let sortOption of genderOptions" [value]="sortOption.value">
                                    {{sortOption.viewValue}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                Anrede <strong>muss</strong> angegeben werden
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <mat-form-field class="full-width">
                            <mat-label>Titel:</mat-label>
                            <input matInput formControlName="title">
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <mat-form-field class="full-width">
                            <mat-label>Geburtstag:</mat-label>
                            <input matInput formControlName="birthday" type="date">
                            <mat-error>
                                Geburtstag <strong>muss</strong> angegeben werden
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row flex">
                    <div class="col-sm-6">
                        <mat-form-field class="full-width">
                            <mat-label>Vorname:</mat-label>
                            <input matInput formControlName="firstName">
                            <mat-error>
                                Vorname <strong>muss</strong> angegeben werden
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="full-width">
                            <mat-label>Nachname:</mat-label>
                            <input matInput formControlName="lastName">
                            <mat-error>
                                Nachname <strong>muss</strong> angegeben werden
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div formGroupName="address">
                <div class="row">
                    <div class="col-sm-12">
                        <mat-form-field class="full-width">
                            <mat-label>Straße und Hausnummer:</mat-label>
                            <input matInput formControlName="street">
                            <mat-error>
                                Straße und Hausnummer <strong>muss</strong>
                                angegeben werden
                            </mat-error>

                        </mat-form-field>
                    </div>

                </div>
                <div class="row marginBottom2Rem">
                    <div class="col-sm-4">
                        <mat-form-field class="full-width">
                            <mat-label>PLZ:</mat-label>
                            <input matInput formControlName="postcode" type="number">
                            <mat-error>
                                PLZ <strong>muss</strong> im richtigen Format angegeben werden
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-8">
                        <mat-form-field class="full-width">
                            <mat-label>Ort:</mat-label>
                            <input matInput formControlName="city">
                            <mat-error>
                                Ort <strong>muss</strong> angegeben werden
                            </mat-error>

                            <mat-error>
                                Ort <strong>muss</strong> angegeben werden
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <h6>Kontaktdaten</h6>
            <div class="row flex">
                <div class="col-sm-6">
                    <mat-form-field class="full-width">
                        <mat-label>Mobil:</mat-label>
                        <input matInput formControlName="mobile" type="number">

                        <mat-error>
                            Mobilnummer <strong>muss</strong> angegeben werden
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="full-width">
                        <mat-label>Mail-Adresse:</mat-label>
                        <input matInput formControlName="email" type="email">
                        <mat-error>
                            E-mail <strong>muss</strong> angegeben werden
                        </mat-error>
                        <mat-error>
                            E-mail <strong>muss</strong> gültiges Format haben
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- <div class="row flex" formGroupName="account">
                <div class="col-sm-12">
                    <mat-form-field class="full-width" appearance="standard">
                        <mat-label>Passwort:</mat-label>
                        <input matInput formControlName="password" type="password">
                        <mat-error>
                            Passwort <strong>muss</strong> angegeben werden
                        </mat-error>
                    </mat-form-field>
                </div>
            </div> -->
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-button mat-raised-button color="primary" (click)="createMemberAndAddToHousehold()" cdkFocusInitial>{{
        data.okText }}</button>
</div>