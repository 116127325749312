import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AccountState } from '../enums/account-state.enum';
import { BusinessEntityResource } from '../interfaces/business-entity-resource';
import { BusinessUserResource } from '../interfaces/business-user-resource';
import { ContactChannelResource } from '../interfaces/contactchannel';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessEntityService {

  constructor(
    private http: HttpClient,
    private environment: EnvironmentService) {


  }

  getAllBuissnesEntities(page, size) {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessentity`, { params })))
  }


  getAllBuissnesEntitiesWithFilter(page, size, state, searchTerm) {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })

    const body = {
      states: state,
      searchTerm: searchTerm
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessentity/search`, body, { params })
      )
    )
  }

  createBusinessentity(businessentity: BusinessEntityResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessentity`, businessentity)))
  }

  addContactChannel(businessentityID, contactChannelResource: ContactChannelResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessentity/${businessentityID}/contactchannel`, contactChannelResource)))
  }

  delteContactChannel(businessentityID, contactChannelResource: ContactChannelResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.delete(`${url}/businessentity/${businessentityID}/contactchannel/${contactChannelResource.channelType}`)
      )
    )
  }

  updateContactChannel(businessentityID, contactChannelResource: ContactChannelResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/businessentity/${businessentityID}/contactchannel`, contactChannelResource)))
  }

  addLogo(businessentityID, file) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessentity/${businessentityID}/logo`, formData)))
  }

  createAnonMember() {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser`, null)))
  }
  updateAnonMember(member: BusinessUserResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser`, member)))
  }

  updateMember(member: BusinessUserResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser`, member)))
  }

  deleteMember(member: BusinessUserResource) {
    member.account.state = AccountState.DELETED;
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser`, member)))
  }

  changeState(businessentity: BusinessEntityResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/businessentity/${businessentity.publicId}`, businessentity)))
  }

  addMember(businessentityID, member: BusinessUserResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessentity/${businessentityID}/member`, member)))
  }

  getMembersToBusinessEntity(businessentityID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessentity/${businessentityID}/member`, {})
      )
    )
  }

  getBusinessEntityService(ID): Observable<BusinessEntityResource> {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessentity/${ID}`, {})))
  }

  updateBusinessEntity(en: BusinessEntityResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/businessentity/${en.publicId}`, en)))
  }

  getPartnerList() {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/partner`, { responseType: 'blob', headers: new HttpHeaders().set('Content-Type', 'application/vnd.ms-excel') })
      ))
  }

  createMemberViaAdmin(member,confirm:boolean){
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/caseworker?confirm=${confirm}`, member)))
  }

 editMemberViaAdmin(member){
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/customer/${member.publicId}`, member)))
  }
}
