<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content [formGroup]="voucherForm">
    <mat-form-field class="full-width">
        <mat-label>Art der externen Gutscheinerzeugung</mat-label>
        <mat-select formControlName="voucherKeyStrategy" (selectionChange)="changeType()">
            <mat-option *ngFor="let sortOption of voucherOptions"
            [value]="sortOption.value" [disabled]="sortOption.state">
            {{sortOption.viewValue}}
        </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width" *ngIf="this.voucherForm.value.voucherKeyStrategy == 'EXTERNAL_KEY_LIST'">
        <mat-label>Gutscheincodes:</mat-label>
        <textarea formControlName="voucherKeys"  matInput rows="8" cols="50"></textarea>
        <mat-error>Es muss mindestens ein Gutscheincode eingegeben werden</mat-error>
    </mat-form-field>
<mat-form-field class="width100"  *ngIf="this.voucherForm.value.voucherKeyStrategy == 'EXTERNAL_SINGLE_KEY' ">
    <mat-label>Aktueller Gutschein-Code:</mat-label>
    <input matInput  formControlName="singleVoucherKey">
    <mat-error>Es muss ein Gutscheincode eingegeben werden</mat-error>

  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-button mat-raised-button color="primary" (click)="saveVoucherChanges()"  cdkFocusInitial>{{ data.okText }}</button>
</div>