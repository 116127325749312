<h3 mat-dialog-title>Stockfotos</h3>
<div mat-dialog-content>
    <ng-container [formGroup]="searchStockImagesForm">
        <div class="row align-center">
            <div class="col-sm-12 col-lg-9">
                <mat-form-field class="full-width">
                    <mat-label>Suchbegriff:</mat-label>
                    <input matInput formControlName="searchSting" (keypress)="serachImagesViaEnter($event)">
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-lg-3">
                <button mat-raised-button class="full-width" color="accent" (click)="serachImages()">Suchen</button>
            </div>
        </div>
    </ng-container>
    <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-12 thumbnail" *ngFor="let i of stockImages" (click)="selectImage(i)">
            <img [src]="i.webformatURL" class="img-responsive width640 img-clickable">
        </div>
        <b *ngIf="stockImages.length == 0 && loadedImages">
            Keine Bild zu diesem Suchbegriff gefunden.
        </b>
    </div>
    <div class="row">
        <div class="align-center">
            <h5>
                Images provided by 
            </h5>
            <a href="https://pixabay.com/" target="_blank">
                <img class="pixabaylogo" src="../../../../assets//imgs/logo-pixabay.svg" alt="Pixabay Logo">
            </a>
        </div>
    </div>
</div>

<!-- DONT REMOVE  => https://pixabay.com/api/docs  -->
<div mat-dialog-actions align="end">

    <button mat-button color="primary" (click)="cancel()">Abbrechen</button>
</div>