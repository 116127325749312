import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CardResource } from 'src/app/interfaces/card-resouce';
import { EventResource } from 'src/app/interfaces/event-resource';
import { CardService } from 'src/app/services/card.service';
import { EventService } from 'src/app/services/event.service';

interface DialogData {
  title: string;
  message: string;
  okText: string;
  card: CardResource;
}

@Component({
  selector: 'app-show-card-qr-code',
  templateUrl: './show-card-qr-code.component.html',
  styleUrls: ['./show-card-qr-code.component.scss']
})
export class ShowCardQrCodeComponent implements OnInit {

  qr: SafeUrl = ''

  constructor(
    public dialogRef: MatDialogRef<ShowCardQrCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private cardSerive:CardService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.cardSerive.getCardQRCode(this.data.card).subscribe(val=>{
      let objectURL = URL.createObjectURL(val);
      this.qr = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    })
  }

  cancel(): void {
    this.dialogRef.close();
  }
}