import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateStatePipe } from './translate-state.pipe'
import { TranslateFilterEventPipe } from './translate-filter-event.pipe'
import { TranslateFilterBusinessentityPipe } from './translate-filter-businessentity.pipe'
import { TranslateFilterReservationPipe } from './translate-filter-reservation.pipe'
import { TranslateFilterCustomerPipe } from './translate-filter-customer.pipe'
import { TranslateFilterTicketPipe } from './translate-filter-ticket.pipe'
import { TranslateFilterServiceStatePipe } from './filter-service/translate-filter-service-state.pipe';
import { TranslateFilterServiceModulePipe } from './filter-service/translate-filter-service-module.pipe';
import { TranslateFilterServiceRolePipe } from './filter-service/translate-filter-service-role.pipe';
import { TranslateFilterHouseholdPipe } from './translate-filter-household.pipe';


@NgModule({
  declarations: [
    TranslateStatePipe,
    TranslateFilterEventPipe,
    TranslateFilterBusinessentityPipe,
    TranslateFilterReservationPipe,
    TranslateFilterCustomerPipe,
    TranslateFilterTicketPipe,
    TranslateFilterServiceStatePipe,
    TranslateFilterServiceModulePipe,
    TranslateFilterServiceRolePipe,
    TranslateFilterHouseholdPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TranslateStatePipe,
    TranslateFilterEventPipe,
    TranslateFilterBusinessentityPipe,
    TranslateFilterReservationPipe,
    TranslateFilterCustomerPipe,
    TranslateFilterTicketPipe,
    TranslateFilterServiceStatePipe,
    TranslateFilterServiceModulePipe,
    TranslateFilterServiceRolePipe,
    TranslateFilterHouseholdPipe,
  ]
})
export class TranslateStateModule { }






