<mat-sidenav-container class="example-container">

  <mat-sidenav #sidenav id="sidenav" mode="over" [(opened)]="opened" [autoFocus]="true" [position]="'start'"  (opened)="this.onMenuOpen()"   (openedStart)="this.onMenuOpen()" style="min-width: 300px;"
    (closed)="events2.push('close!')">
    <mat-list class="logoContainer" *ngIf="finishedLoadingToken" [routerLink]="['/']">
      <mat-list-item>
        <app-mandant-logo></app-mandant-logo>
      </mat-list-item>
      <mat-list-item>
        <div mat-line>
          <b> {{this.user?.person?.displayName}}</b>
          <br>
          {{this.user?.account?.role?.displayName}}
        </div>

      </mat-list-item>
    </mat-list>

    <div class="loadingContainerMenu" *ngIf="!finishedLoadingToken">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
    <mat-divider></mat-divider>

    <mat-nav-list>
      <ng-container *ngFor="let menuItem of modulesList; let i = index;">
        <div mat-subheader>{{ menuItem.label }}</div>
        <mat-list-item *ngFor="let childL1 of menuItem.children;let j = index" (click)="sidenav.close()" [disabled]="childL1.disabled"[routerLink]="childL1.disabled ? null : [childL1.path] "
          [queryParams]="childL1.queryParams" [routerLinkActive]="['is-active']" [class.disabled]="childL1.disabled" id="nav_element_{{i}}_{{j}}">
          <mat-icon mat-list-icon>{{childL1.icon}}</mat-icon>
          <div mat-line>{{ childL1.label }} </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>
      <mat-divider></mat-divider>
      <mat-list-item *ngFor="let logoutEntry of logout" (click)="logoutUser()" style="margin-bottom: 45px;">
        <mat-icon mat-list-icon>{{logoutEntry.icon}}</mat-icon>
        <div mat-line>{{logoutEntry.name}}</div>
      </mat-list-item>
    </mat-nav-list>
    <div class="version">Version {{currentApplicationVersion}}</div>
  </mat-sidenav>

  <mat-sidenav-content  id="Sidenav">
    <app-navigation [sidenav]="sidenav"> </app-navigation>
    <loading-bar></loading-bar>
    <div class="content-wrapper">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>