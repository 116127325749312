import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { EnvironmentService } from '../services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class HttpsRequestInterceptorJwt implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService,
    private environmentService:EnvironmentService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    const currentUser = this.authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;
    if (isLoggedIn && this.isValidRequestForInterceptor(request.url,this.environmentService.getClientId())) {
      let modifiedRequest = request.clone({
        setHeaders: {
          authorization: `Bearer ${currentUser.token}`
        }
      });
      return next.handle(modifiedRequest);
    } else {
      return next.handle(request);
    }
  }

  private isValidRequestForInterceptor(requestUrl: string,clientID): boolean {
    if(requestUrl.includes("https://pixabay.com/api?key=24605956-982d3aa6be88eb55a8816bbce" )||(requestUrl.includes(`https://mcp.swaas.de/client/${clientID}/property` )) || (requestUrl.includes(`https://mcp.swaas.de/client/${clientID}/property`))){
      return false
    }else{
      return true;
    }
    
  }
}
