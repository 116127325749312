export enum State {
    NEW = 'NEW', 
    FAILED = 'FAILED', 
    RESERVED = 'RESERVED', 
    USED = 'USED', 
    EXPIRED = 'EXPIRED', 
    CANCELLED = 'CANCELLED', 
    REJECTED = 'REJECTED',
    CREATED = 'CREATED', 
    DRAFT = 'DRAFT', 
    PREVIEW = 'PREVIEW', 
    ACTIVE = 'ACTIVE', 
    HIDDEN = 'HIDDEN', 
    ARCHIVED = 'ARCHIVED', 
    DELETED = 'DELETED',
    PUBLISHED = 'PUBLISHED',
    PENDING = 'PENDING',
    DISABLED = 'DISABLED',
    VALIDATION = 'VALIDATION',
    VALID = 'VALID',
    INVALID = 'INVALID',
    DISABLE_REQUEST = 'DISABLE_REQUEST'
}
