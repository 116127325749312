import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { map, throwIfEmpty } from 'rxjs/operators';
import { JwtHelperService } from "@auth0/angular-jwt";
import { OverlayService } from './overlay.service';
import { Location } from '@angular/common';
import config from './../../assets/config/psconfig.json';
import { AccessTokenData } from '../interfaces/access-token-data';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private helper = new JwtHelperService();
  public decodedToken$: BehaviorSubject<AccessTokenData> = new BehaviorSubject(null);
  private clientConfig = config;
  public userRole = null;

  constructor(
    private http: HttpClient,
    private environment: EnvironmentService,
    private overlayService: OverlayService,
    private location: Location,
    private environmentService: EnvironmentService,
    private router:Router
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();


    this.loadClientConfig();
  }

  private loadClientConfig() {
    const data = JSON.parse(localStorage.getItem('currentUser'))
    if (data && data.token) {
      const decodedToken = this.helper.decodeToken(data.token);
      const config = JSON.parse(localStorage.getItem('config'))
      this.setCurrentUser(data, config);
      this.decodedToken$.next(decodedToken)
    }
  }

  getClientSystemList() {
    const filtered = Object.keys(this.clientConfig).filter(id => id.indexOf('LIVE_') >= 0);
    filtered.push('DEMO_100');
    filtered.push('DEV_666');

    return filtered.map(key => this.clientConfig[key])
      .map(entry => {
        entry['DISPLAY_NAME'] = entry['DISPLAY_NAME'].replace(' (Live)', '');
        entry['DISPLAY_NAME'] = entry['DISPLAY_NAME'].replace(' (LIVE)', '');
        return entry
      })
      .sort((a, b) => {
        return a.DISPLAY_NAME.localeCompare(b.DISPLAY_NAME);
      });
  }

  getClientConfigById(clientId) {
    const configs = this.getClientSystemList();
    return configs.filter(config => config.clientId == clientId)[0];
  }

  getTokenForClient(newClientUrl, clientId) {
    return this.http.post(`${newClientUrl}/system-token`, { token: this.currentUserValue.token }).pipe(
      map(res => {
        const config = this.getClientConfigById(clientId);
        this.setCurrentUser({ token: res['token'] }, config);
      })
    )
  }

  login(username: string, password: string, config) {
    this.environment.setClientConfig(config);

    return this.http
      .post<any>(`${this.environment.getApiUrl()}/authorize`, {
        username,
        password
      })
      .pipe(
        map(user => {
          // console.log('GOT USER: ', user);
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.setCurrentUser(user, config);
          }
          return user;
        })
      );
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  public validateTokenAndSetUser(token, clientId) {
    try {
      const decodedToken = this.helper.decodeToken(token);
      const expirationDate = this.helper.getTokenExpirationDate(token);
      const isExpired = this.helper.isTokenExpired(token);
      // console.log('decodedToken: ', decodedToken);
      // console.log('expirationDate: ', expirationDate);
      // console.log('isExpired: ', isExpired);
      const config = this.getClientConfigById(clientId);
      // console.log('config for user: ', config);

      if (!isExpired) {
        this.setCurrentUser({ token: token }, config);
      } else {
        this.overlayService.openSnackBar('Der Token ist abgelaufen.', 'error');
        this.location.replaceState('');
      }
    } catch (e) {
      this.overlayService.openSnackBar('Ungültiger Token.', 'error');
      this.location.replaceState('');
    }
  }

  public setCurrentUser(user, config) {
    const decodedToken = this.helper.decodeToken(user.token);

    if (decodedToken.clientId == 666 && decodedToken.role == 'ROLE_ADMIN') {
      this.userRole = 'superadmin';
    } else if (decodedToken.role == 'ROLE_CASEWORKER') {
      this.userRole = 'sb';
    } else if (decodedToken.role == 'ROLE_ADMIN') {
      this.userRole = 'admin';
    } else if (decodedToken.role == 'ROLE_PARTNER') {
      this.userRole = 'partner';
    }

    this.decodedToken$.next(decodedToken)
    this.environment.setClientConfig(config);
    localStorage.setItem('currentUser', JSON.stringify({ token: user.token, }));
    let config2 = {
      "id": environment.clientID,
      "name": environment.appName,
      "backend": environment.apiURL,
      "property": 1
    }
    if(environment.clientID == null){
      localStorage.setItem('config', JSON.stringify(config));
    }else{
      localStorage.setItem('config', JSON.stringify(config2));
    }
    this.currentUserSubject.next(user);
  }

  public logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('config');
    this.userRole = null;
    this.currentUserSubject.next(null);
    //window.location.reload();
    this.router.navigate(['login'])
  }

  public isSuperAdmin() {
    return this.userRole == 'superadmin';
  }

  public isSB() {
    return this.userRole == 'sb';
  }

  public isAdmin() {
    return this.userRole == 'admin';
  }

  public isPartner() {
    return this.userRole == 'partner';
  }
}
