import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessUserResource } from 'src/app/interfaces/business-user-resource';
import { BusinessuserService } from 'src/app/services/businessuser.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { ConfirmedValidator } from 'src/app/Validators/ConfirmedValidator';


interface DialogData {
  title: string;
  message: string;
  okText: string;
  user: BusinessUserResource;
}

@Component({
  selector: 'app-reset-pwfor-user',
  templateUrl: './reset-pwfor-user.component.html',
  styleUrls: ['./reset-pwfor-user.component.scss']
})



export class ResetPWForUserComponent implements OnInit {
  pwForm: UntypedFormGroup;
  hidePW = true;
  hidePWConfirm = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ResetPWForUserComponent>,
    private fb: UntypedFormBuilder,
    private businessuserService: BusinessuserService,
    private overlayService: OverlayService) {
    this.pwForm = this.fb.group({
      newPassword: ["", [Validators.required, Validators.minLength(6)]],
      passwordRecitation: ["", [Validators.required, Validators.minLength(6)]]
    }, {
      validator: ConfirmedValidator('newPassword', 'passwordRecitation')
    })
  }

  ngOnInit(): void {
    console.log(this.data)
  }


  setPW() {
    this.pwForm.markAllAsTouched();
    if (this.pwForm.invalid) {
      return;
    }
    this.businessuserService.setPassword(this.data.user, this.pwForm.value.newPassword, this.pwForm.value.passwordRecitation).subscribe(fromSetPW => {
      console.log(fromSetPW)
      if(fromSetPW){
        this.overlayService.openSnackBar("Der Nutzer kann sich mit dem vorläufigem Passwort anmelden, muss es nach erstmaliger Anmeldung jedoch wieder ändern.");
        this.dialogRef.close();
      }

    })
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
