import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BusinessUserResource } from 'src/app/interfaces/business-user-resource';
import { BusinessuserService } from 'src/app/services/businessuser.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { ChangeEventStateComponent } from '../change-event-state/change-event-state.component';

interface DialogData {
  businessUserResource: BusinessUserResource;
}

@Component({
  selector: 'app-change-business-user-role',
  templateUrl: './change-business-user-role.component.html',
  styleUrls: ['./change-business-user-role.component.scss']
})
export class ChangeBusinessUserRoleComponent implements OnInit {

  public list = [
    { value: "ROLE_CUSTOMER", viewValue: "Kunde" },
    { value: "ROLE_PRE_CUSTOMER", viewValue: "Vorkunde" },
    { value: "ROLE_PARTNER", viewValue: "Aktionspartner" },
    { value: "ROLE_ANON", viewValue: "Anonym" },

  ]
  constructor(private businessuserService: BusinessuserService, @Inject(MAT_BOTTOM_SHEET_DATA) public data: DialogData,
    public dialogRef: MatBottomSheetRef<ChangeEventStateComponent>,
    private overlayService: OverlayService) { }

  ngOnInit(): void {
    console.log(this.data.businessUserResource.account.state)
  }
  changeState(e) {
    console.log(e)

    this.businessuserService.changeRole(this.data.businessUserResource, e).subscribe((value:BusinessUserResource) => {
      if (value.publicId != null) {
        this.overlayService.openSnackBar('Status erfolgreich geändert!');
        this.dialogRef.dismiss();
      }
    })
  }
}
