import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PixabayService } from 'src/app/services/pixabay.service';

@Component({
  selector: 'app-stock-images-gallery',
  templateUrl: './stock-images-gallery.component.html',
  styleUrls: ['./stock-images-gallery.component.scss']
})
export class StockImagesGalleryComponent implements OnInit {
  stockImages = [];
  loadedImages: boolean = false;
  searchStockImagesForm:UntypedFormGroup ;
  constructor(
    public dialogRef: MatDialogRef<StockImagesGalleryComponent>,
    private fb: UntypedFormBuilder,
    private pixabayService: PixabayService) { }
  



  ngOnInit(): void {
    this.searchStockImagesForm = this.fb.group({
      searchSting : ['',[Validators.required]]
    })
  }

  cancel(): void {
    this.dialogRef.close();
  }
  serachImagesViaEnter(keyEvent){
    if (keyEvent.which === 13) {
      this.pixabayService.searchForImage(this.searchStockImagesForm.value.searchSting).subscribe((images: any) => {
        this.stockImages = images.hits
        this.loadedImages = true;
      })
  }

  }
  serachImages(){
    this.pixabayService.searchForImage(this.searchStockImagesForm.value.searchSting).subscribe((images: any) => {
      this.stockImages = images.hits;
      this.loadedImages = true;
    })
  }
  selectImage(image){
    this.dialogRef.close(image);
  }
}
