import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateState'
})
export class TranslateStatePipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case "CREATED":
        return "In Freigabe"
      case "DRAFT":
        return "Entwurf"
      case "PREVIEW":
        return "Vorschau"
      case "ACTIVE":
        return "Aktiv"
      case "HIDDEN":
        return "Versteckt"
      case "CANCELLED":
        return "Storniert"
      case "EXPIRED":
        return "Abgelaufen"
      case "ARCHIVED":
        return "Archiviert"
      case "DELETED":
        return "Gelöscht"
      case "DISABLED":
        return "Deaktiviert"
      case "EVENT_PARTNER":
        return "Aktionspartner"
      case "SERVICE_CENTER":
        return "Service-Einheit"
      case "MAINCUSTOMER":
        return "Kunde"
      case "SUBCUSTOMER":
        return "Haushaltsmitglied"
      case "NEW":
        return "Neu"
      case "New":
        return "Neu"
      case "PENDING":
        return "Wartend"
      case "REJECTED":
        return "Abgelehnt"
      case "DISABLE_REQUEST":
        return "Deaktivierung beantragt"
      case "NEWS":
        return "Nachricht"
      case "APPOINTMENT":
        return "Veranstaltung"
      case "PUBLISHED":
        return "Öffentlich"
      case "MALE":
        return "Herr"
      case "FEMALE":
        return "Frau"
      case "MATCH":
        return "Übereinstimmung"
      case "PARTIAL_MATCH":
        return "Partielle Übereinstimmung"
      case "NO_MATCH":
        return "Keine Übereinstimmung"
      case "IGNORE":
        return "Nie überschreiben"
      case "LEFT":
        return "Bild links"
      case "RIGHT":
        return "Bild rechts"
      case "NONE":
        return "Bild nicht anzeigen"
      case "NO_PAYMENT":
        return "Kostenlos"
      case "PAID":
        return "Bezahlt"
      case "RESERVED":
        return "Reserviert"
      case "RESERVED":
        return "Reserviert"
      case "USED":
        return "Eingelöst"
      case "CLOSED":
        return "Geschlossen"
      case "BASIC":
        return "nicht definiert"
      case "SIGN_UP":
        return "Kunde werden o. Tarif wechseln"
      case "MEASURE":
        return "Zählerstand"
      case "PART_PAYMENT":
        return "Abschlagsänderung"
      case "BANK_DETAILS":
        return "Bankdatenänderung"
      case "RELOCATION_V1":
        return "Ummeldung"
      case "RELOCATION_V2":
        return "Ummeldung"
      case "MASTERDATA":
        return "Stammdatenänderung"
      case "PHOTOVOLTAICS":
        return "Photovoltaik"
      case "PROSPECT":
        return "Wechselprozess"
      case "INQUIRY":
        return "Allgemeine Anfrage"
      case "ASSIGNED":
        return "Angefordert"
      case "REQUESTED":
        return "Zugeordnet"
      case "asc":
        return "Aufsteigend"
      case "desc":
        return "Absteigend"
      case "INVOICED":
        return "Zugestellt"
      case "id":
        return "ID"
      case "title":
        return "Titel"
      case "filename":
        return "Dateiname"
      case "originalFilename":
        return "Dateiname (Original)"
      case "contentType":
        return "Dateityp"
      case "created":
        return "Dateityp"
      case "erstellt":
        return "zuletzt geändert"
      case null:
        return "Keiner (null)"
      default:
        return value;
    }
  }
}
