import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessEntityService } from 'src/app/services/business-entity.service';
export interface DialogData {
  title: string;
  message: string;
  okText: string;
  cancelText: string;
}

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
  continue() {
    this.dialogRef.close(true);
  }
}
