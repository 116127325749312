import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, combineLatest, from, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  private currentEnv = {
    url: (environment.apiURL == 'null') ? '' : environment.apiURL,
    clientId: (environment.clientID == null) ? '' : environment.clientID,
    color: '#ff00ff'
  }

  constructor(private router: Router) { }

  public setClientConfig(config) {
    if (config != null) {
      this.currentEnv = {
        url: config.backend,
        clientId: config.id,
        color: '#ff00ff'
      }
    } else {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('config');
      this.router.navigate(['login']);
    }

  }

  get apiV1$(): Observable<string> {
    return combineLatest([
      of(localStorage.getItem('config'))
    ]).pipe(
      map(([configuration]) => {
        let env = JSON.parse(configuration);
        return `${env.backend}/api/v1/client/${env.id}`;
      })
    );
  }

  get apiV2$(): Observable<string> {
    return combineLatest([
      of(localStorage.getItem('config'))
    ]).pipe(
      map(([configuration]) => {
        let env = JSON.parse(configuration);
        return `${env.backend}/api/v2/client/${env.id}`;
      })
    );
  }

  public getApiUrl(v = 'v1') {
    return `${this.currentEnv.url}/api/${v}/client/${this.currentEnv.clientId}`;
  }

  public getApiUrlLogo(v = 'v1') {
    return `${this.currentEnv.url}/api/${v}/client/`;
  }

  public getApiUrlLogoBaeder(v = 'v1') {
    return `${this.currentEnv.url}/${this.currentEnv.clientId}/dynamic/`;
  }

  /**
   * @deprecated
   * @param v 
   * @returns 
   */
  public getApiUrlWithV(v) {
    return `${this.currentEnv.url}/api/${v}/client/${this.currentEnv.clientId}`;
  }

  public getApiPlain(v = 'v1') {
    return `${this.currentEnv.url}/api/${v}/client`;
  }

  public getClientId() {
    return this.currentEnv.clientId;
  }

  public getUrl() {
    return this.currentEnv.url;
  }
}
