import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { BusinessUserResource } from '../interfaces/business-user-resource';
import { AccountState } from '../enums/account-state.enum';
import { BusinessEntityResource } from '../interfaces/business-entity-resource';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ContactChannelResource } from '../interfaces/contactchannel';
import { PagedResources } from '../interfaces/paged-resources';
import { element } from 'protractor';
import { AuthenticationService } from './authentication.service';
import { supplyContract } from '../interfaces/supplyContract';

export interface BusinessUserFilter {
  filterByRoles: Array<string>,
  filterByStates: Array<string>,
  searchTerm: string,
}

@Injectable({
  providedIn: 'root'
})
export class BusinessuserService {
  businesUserCount$: Subject<number> = new ReplaySubject(1);
  MainCustomerCount$: Subject<number> = new ReplaySubject(1);
  SubCustomerCount$: Subject<number> = new ReplaySubject(1);

  pendingCustomers$: Subject<number> = new ReplaySubject(0);
  waitingForDeactivation$: Subject<number> = new ReplaySubject(0);
  partialMatch$: Subject<number> = new ReplaySubject(0);
  householeBiggerThanThree$: Subject<number> = new ReplaySubject(0);
  nonEligibleUsers$: Subject<number> = new ReplaySubject(0);
  newCards$: BehaviorSubject<number> = new BehaviorSubject(null);
  firstCards$: BehaviorSubject<number> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private environment: EnvironmentService,
    private auth: AuthenticationService) {
    if (this.auth.userRole != 'admin') {
      this.getAllBusinessUserCount(0, 1).subscribe(value => {
        this.businesUserCount$.next(value.page.totalElements)
      })

      this.getAllMainCustomersCount(0, 1).subscribe(value => {
        this.MainCustomerCount$.next(value.page.totalElements)
      })

      this.getAllSubCustomersCount(0, 1).subscribe(value => {
        this.SubCustomerCount$.next(value.page.totalElements)
      })

      this.getAllBusinessUsersWtihFilter(0, 1, null, null, ["PENDING"], null, null, null, null, null).subscribe((val: PagedResources<BusinessUserResource>) => {
        this.pendingCustomers$.next(val.page.totalElements)
      })

      this.getAllBusinessUsersWtihFilter(0, 1, null, null, ["DISABLE_REQUEST"], null, null, null, null, null).subscribe((val: PagedResources<BusinessUserResource>) => {
        this.waitingForDeactivation$.next(val.page.totalElements)
      })

      this.getAllBusinessUsersWtihFilter(0, 1, null, null, null, null, null, "PARTIAL_MATCH", null, null).subscribe((val: PagedResources<BusinessUserResource>) => {
        this.partialMatch$.next(val.page.totalElements)
      })

      this.getNonEligibaleUsers().subscribe((val: PagedResources<BusinessUserResource>) => {
        this.nonEligibleUsers$.next(val.page.totalElements)
      })

      this.getAllBusinessUsersWtihFilter(0, 1, null, ["MAINCUSTOMER"], ["ACTIVE"], null, ["CREATED"], null, null, null).subscribe((val: PagedResources<BusinessUserResource>) => {
        this.newCards$.next(val.page.totalElements)
      })

      this.getFirstCardCount().subscribe((val: PagedResources<BusinessUserResource>) => {
        this.firstCards$.next(val.page.totalElements)
      })

    } else {
      this.MainCustomerCount$.next(1)
    }



    //TODO get households with more than 3 adults
    // this.getAllBusinessUsers(0, 1).subscribe((user: PagedResources<BusinessUserResource>) => {
    //   this.getAllBusinessUsers(0, user.page.totalElements).subscribe((users: PagedResources<BusinessUserResource>) => {
    //     let usersWith3 = [];
    //     var bar = new Promise((resolve, reject) => {
    //       users.content.forEach((element, index, array) => {
    //         this.getCustomerGroup(element).subscribe((val: any) => {
    //           if (val.length >= 3) {
    //             var yearsAgo = new Date();
    //             yearsAgo.setFullYear(yearsAgo.getFullYear() - 18);
    //             let tempHouseHold = []
    //             val.forEach((element1: BusinessUserResource) => {
    //               if (new Date(element1.person.birthday) <= yearsAgo) {
    //                 tempHouseHold.push(element1)
    //               }
    //             });
    //             if (tempHouseHold.length >= 3) {
    //               usersWith3.push(tempHouseHold);
    //             }
    //           }
    //         })
    //         if (index === array.length - 1) {
    //           resolve(true);
    //         }
    //       });
    //     });
    //     bar.then(() => {
    //       console.log('All done!', usersWith3);
    //       this.householeBiggerThanThree$.next(usersWith3.length)
    //     });
    //   })
    // })


  }

  search(page, size, searchTerm, filterByRoles, filterByStates, type): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      searchTerm: searchTerm,
      filterByStates: filterByStates,
      type: type,
      filterByRoles: filterByRoles
    }


    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser/search`, body, { params })
      )
    )
  }

  getAllBusinessUsers(page, size): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })

    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser`, { params }))
    )
  }

  getAllBusinessUserCount(page, size): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByStates: ['ACTIVE'],
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser/search`, body, { params })
      )
    )
  }


  getAllMainCustomersCount(page, size): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByStates: ['ACTIVE'],
      filterByCustomerType: ['MAINCUSTOMER']
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/search`, body, { params })))
  }


  getAllSubCustomersCount(page, size): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByStates: ['ACTIVE'],
      filterByCustomerType: ['SUBCUSTOMER']
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/search`, body, { params })
      )
    )
  }

  getAllBusinessUsersWtihFilter(page, size, searchTerm, filterByCustomerType, filterByState, filterBySupplyContractState, filterByCardState, erpState, hasNoValidELV, hasNoCard, filterByRoles?): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      searchTerm: searchTerm,
      filterByCustomerType: filterByCustomerType,
      filterByState: filterByState,
      filterBySupplyContractState: filterBySupplyContractState,
      filterByCardState: filterByCardState,
      erpState: erpState,
      hasNoValidELV: hasNoValidELV,
      hasNoCard: hasNoCard,
      filterByRoles: filterByRoles
    }

    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/search`, body, { params })
      )
    )
  }



  getNonEligibaleUsers() {
    const params = new HttpParams({
      fromObject: {
        page: 0,
        size: 1
      }
    })

    const body = {
      hasNoValidELV: true,
      filterByCustomerType: ["MAINCUSTOMER"],
      filterByState: ["ACTIVE"],
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/search`, body, { params })
      )
    )
  }


  getFirstCardCount() {
    const params = new HttpParams({
      fromObject: {
        page: 0,
        size: 1
      }
    })

    const body = {
      hasNoCard: true,
      filterByState: ["ACTIVE"],
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/search`, body, { params })
      )
    )
  }


  getBusinessUser(id) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/customer/${id}`)
      )
    )
  }

  getBusinessUserForMenu(id) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${id}`)
      )
    )
  }

  addBusinessUser(data) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/customer`, data)))
  }

  updateBusinessUser(id, data) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${id}`, data)
      )
    )
  }

  resetPassword(id, data) {
    const body = { accountPublicId: id, ...data };
    console.log('reset: ', body);
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/account/${id}/password`, body)))
  }

  changeState(member: BusinessUserResource, state) {
    member.account.state = state;
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser`, member)
      )
    )
  }

  changeRole(member: BusinessUserResource, role) {
    member.account.role.name = role;
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser`, member)
      )
    )
  }


  getERPDataForNewCustomer(page, size, searchTerm): Observable<any> {
    const body = {
      searchTerm: searchTerm
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/erp/search?page=${page}&size=${size}`, body)
      )
    )
  }

  activateERPUser(confirm, user): Observable<any> {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/caseworker?confirm=${confirm}`, user, {})
      )
    )
  }

  getBuissnessUser(publicID: string) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/customer/${publicID}`, {})
      )
    )
  }

  getBuissnessUserAdmin(publicID: string) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${publicID}`, {})
      )
    )
  }

  editBuissnessUser(customer) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/businessuser/${customer.publicId}`, customer)
      )
    )
  }

  editBuissnessUserAdmin(customer) {
    return this.http.put(`${this.environment.getApiUrl()}/businessuser/${customer.publicId}`, customer);
  }
  getGroupMemersByID(publicId: string) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${publicId}/group`, {})))
  }

  getContactChannels(user: (BusinessEntityResource)) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/customer/${user.publicId}/contactchannel`, {})
      )
    )
  }

  getContactChannelsByID(publicId: string) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${publicId}/contactchannel`)
      )
    )
  }

  createContactChannel(businesUserID, contactChannelResource: ContactChannelResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser/${businesUserID}/contactchannel`, contactChannelResource)
      )
    )
  }

  updateContactChannel(businesUserID, contactChannelResource: ContactChannelResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/businessuser/${businesUserID}/contactchannel/${contactChannelResource.publicId}`, contactChannelResource)
      )
    )
  }

  delteContactChannel(businesUserID, contactChannelResource: ContactChannelResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.delete(`${url}/businessuser/${businesUserID}/contactchannel/${contactChannelResource.publicId}`)
      )
    )
  }

  deactivateUser(customer) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/${customer.publicId}/deactivate`, {})
      )
    )
  }

  deactivateUserForever(customer) {
    customer.account.state = 'DISABLED';
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.patch(`${url}/customer/${customer.publicId}`, customer)))
  }


  activateUserWithoutNotification(customer: BusinessUserResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.patch(`${url}/customer/${customer.publicId}/activate?notify=false`,{})))
  }

  activateUserWithNotification(customer: BusinessUserResource) {

    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.patch(`${url}/customer/${customer.publicId}/activate?notify=true`,{})))
  }

  getCustomerGroup(customer) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${customer.publicId}/group`, {})))
  }
  getCustomerGroupWithID(customerID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${customerID}/group`, {})
      )
    )
  }

  addToCustomerGroup(customer, member) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/${customer.publicId}/group/member`, member)))
  }

  getGroupHead(customerID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${customerID}/group/head`, {})))
  }

  setPassword(user: BusinessUserResource, pw, confirm) {
    let body = {
      accountPublicId: user.publicId,
      newPassword: pw,
      passwordRecitation: confirm
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/account/${user.publicId}/password`, body)
      )
    )
  }


  getStatsForHouseHold(userID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event/stats?customerPublicId=${userID}`, {})))
  }


  activateMail(contractID, value) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    const body = value
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businesscontract/${contractID}/is-partner-mail-recipient`, body, options)
      )
    )
  }

  getAccountOptionsForAccount(businesUserID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/account/${businesUserID}/option`)
      )
    )
  }

  editAccountOptionsForAccount(businesUserID, accountOptions) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/account/${businesUserID}/option`, accountOptions)
      )
    )
  }

  getAccountNote(businesUserID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/account/${businesUserID}/note`, { responseType: 'text' })
      )
    )
  }

  editAccountNote(businesUserID, text) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/account/${businesUserID}/note`, text, { responseType: 'text' })
      )
    )
  }

  getUserProperties(businesUserID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${businesUserID}/property`)
      )
    )
  }

  editUserProperties(businesUserID, prop) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser/${businesUserID}/property`, prop)
      )
    )
  }


  getContractsForUser(businesUserID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/customer/${businesUserID}/contract`)
      )
    )
  }

  editContractForUser(businesUserID, contract: supplyContract) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/customer/${businesUserID}/contract/${contract.supplyContractRef}`, contract)
      )
    )
  }

  addContractToUser(businesUserID, contract: supplyContract) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/${businesUserID}/contract`, contract)
      )
    )
  }

  deleteContractFromUser(businesUserID, contract: supplyContract) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.delete(`${url}/customer/${businesUserID}/contract/${contract.supplyContractRef}`)
      )
    )
  }

  searchForContract(page, size, businesUserID, filterByStates, filterByTypes) {
    const body = {
      filterByStates: filterByStates,
      filterByTypes: filterByTypes
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/${businesUserID}/contract/search?page=${page}&size=${size}`, body)
      )
    )
  }

  getContractData(businesUserID, contractID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/customer/${businesUserID}/contract`).pipe(
        map((contracts: Array<supplyContract>) => {
          return contracts.filter(contract => contract.supplyContractRef == contractID)[0]
        })
      )
      )
    )
  }

  getDSGVOExport(customer) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/customer/${customer.publicId}/zip`, { responseType: 'blob' })
      )
    )
  }
}
