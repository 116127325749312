<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content>
    <mat-list role="list">
        <mat-list-item role="listitem" *ngFor="let item of usedVouchers">{{item.voucherKey}} am {{item.usedAt | date:
            'dd.MM.yyyy'}}</mat-list-item>
    </mat-list>

    <h5 *ngIf="usedVouchers.length == 0">
        Bisher wurden keine Gutscheine eingelöst.
    </h5>

</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-button mat-raised-button color="primary" cdkFocusInitial>{{ data.okText }}</button>
</div>