import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateFilterServiceState'
})
export class TranslateFilterServiceStatePipe implements PipeTransform {
  transform(value: string[]): string[] {
    let returnArray = [];
    value.forEach((element, index) => {
      switch (element) {
        case 'ACTIVE':
          if (returnArray.length == 0) {
            returnArray.push("aktiviert");
          }
          break;
        case 'DISABLED':
          if (returnArray.length == 0) {
            returnArray.push("deaktiviert");
          }
          break;
        default:
          returnArray.push(element);
          break;
      }
    });
    return returnArray

  }

}
