import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateExportType'
})
export class TranslateExportTypePipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case "MASTER_DATA_REPORT":
        return "Stammdaten Report"
      case "MANUAL_ERP_REPORT":
        return "Manueller ERP Sync Report"
      case "CUSTOMERS_XML":
        return "Kunden XML"
      case "CARDS_CSV":
        return "Kunden Karten Urladung (axxteq)"
      default:
        return value;
    }
  }

}
