import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { McpService } from 'src/app/services/mcp.service';
import { Mandant } from 'src/app/interfaces/mandant';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { OverlayService } from 'src/app/services/overlay.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  title:string = environment.appName;
  userForm: UntypedFormGroup;
  show: boolean = false;
  hasClientSetPerURL: boolean = false;
  systemConfigs = [];
  environment = environment;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private mcp: McpService,
    private loadingService: LoadingService,
    private overlayService: OverlayService) {
    this.userForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      system: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.mcp.getClients().subscribe(mandantMap => {
      const ids = Object.keys(mandantMap);
      let mandants: Mandant[] = ids.map((id) => {
        return { id: id, ...mandantMap[id] };
      });
      this.systemConfigs = mandants.filter(x => {
        return x['property'] == 1
      });
      this.systemConfigs.push(
        {
          id: '100',
          name: 'Demo',
          backend: 'https://demo.plusservices.de', 
          property: 1
        }
      )

      this.systemConfigs.push(
        {
          id: '144',
          name: 'Stadtwerke Warendorf',
          backend: 'https://stadtwerke-warendorf.plusservices.de',
          property: 1
        }
      )

      this.systemConfigs.push(
        {
          id: '100',
          name: 'Vertrieb',
          backend: 'https://vertrieb.plusservices.de',
          property: 1
        }
      )

      this.systemConfigs.push(
        {
          id: '145',
          name: 'Showcase',
          backend: 'https://develop.plusservices.de',
          property: 1
        }
      )

      if(environment.clientID != null){
        this.userForm.controls['system'].removeValidators([Validators.required]);
        this.userForm.controls['system'].updateValueAndValidity();

        console.log(this.userForm)
      }
      
      this.route.queryParams.subscribe(params => {
        this.systemConfigs.forEach(config => {
          if (config.id == params.clientid) {
            this.userForm.patchValue({
              system: config
            }),
              this.hasClientSetPerURL = true;
          }
        });
      });
    })

    // Automatic login, probably perform at higher level like app.component
    if (this.authenticationService.currentUserValue) {
      this.routeAuthenticatedUser();
    }
  }

  login() {
    this.loadingService.show();
    this.authenticationService.login(this.userForm.value.username, this.userForm.value.password, this.userForm.value.system).subscribe({
      next: () => {
        this.routeAuthenticatedUser();
      },
      error: (err: HttpErrorResponse) => {
        console.log(err)
        if (err.status == 401) {
          this.overlayService.openSnackBar('Diese Kombination aus E-Mail und Passwort ist uns leider nicht bekannt.')
        }
        this.loadingService.hide();
      }
    })
  }

  routeAuthenticatedUser() {
    if (this.authenticationService.isSuperAdmin()) {
      this.router.navigateByUrl('/admin/client-list');
    } else if (this.authenticationService.isAdmin()) {
      this.router.navigateByUrl('/admin');
    } else if (this.authenticationService.isSB()) {
      this.router.navigateByUrl('/caseworker');
    } else if (this.authenticationService.isPartner()) {
      this.router.navigateByUrl('/partner');
    }
  }
}
