import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { EventResource } from 'src/app/interfaces/event-resource';
import { EventService } from 'src/app/services/event.service';

interface DialogData {
  title: string;
  message: string;
  okText: string;
  event: EventResource;
}


@Component({
  selector: 'app-show-qr-code',
  templateUrl: './show-qr-code.component.html',
  styleUrls: ['./show-qr-code.component.scss']
})
export class ShowQrCodeComponent implements OnInit {
  qr: SafeUrl = ''

  constructor(
    public dialogRef: MatDialogRef<ShowQrCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private eventService:EventService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.eventService.getQRCodeAsPNG(this.data.event).subscribe((val:any)=>{
      let objectURL = URL.createObjectURL(val);
      this.qr = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      console.log(this.qr)
    })
   
  }
  cancel(): void {
    this.dialogRef.close();
  }
}
