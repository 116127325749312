import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { Contact } from '../interfaces/contact';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { isEmpty, mapTo, startWith, switchMap } from 'rxjs/operators';
import { ClientResource } from '../interfaces/client-resource';
import { McpService } from './mcp.service';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  properties$: BehaviorSubject<any> = new BehaviorSubject(null);
  logo$: Subject<string> = new ReplaySubject(1);
  component_web_landing_page$: Subject<string> = new ReplaySubject(null);
  component_app_service_center$: Subject<string> = new ReplaySubject(null);
  component_web_intro_landing_page$: Subject<string> = new ReplaySubject(null);
  component_advent$: Subject<string> = new ReplaySubject(null);

  contact$: Subject<Contact> = new ReplaySubject(1)
  maxAccountsHousehold$: Subject<number> = new ReplaySubject(1)
  formallyAddressing$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  salutation$ = new BehaviorSubject(null)
  portal_label$ = new BehaviorSubject(null)
  clientName$ = new BehaviorSubject(null)
  mandantPrimary$: BehaviorSubject<string> = new BehaviorSubject(null)
  mandantSecondary$: BehaviorSubject<string> = new BehaviorSubject(null)
  phoneContact$: BehaviorSubject<string> = new BehaviorSubject(null)
  favicon$: BehaviorSubject<string> = new BehaviorSubject(null)

  clientInfo$: BehaviorSubject<any> = new BehaviorSubject(null)
  constructor(
    private http: HttpClient,
    private environment: EnvironmentService,
    private mcp: McpService) {
    this.getProperty();

  }

  getProperties() {
    return this.http.get(`${this.environment.getApiPlain()}/property`);
  }

  getPropertyConfiguration() {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/property`))
    )
  }

  updateProperty(publicId, value) {
    // https://develop.plusservices.de/api/v1/client/100/property/max.accounts.household/
    const body = {
      key: publicId,
      // links: [{rel: "self",…}]
      // publicId: "max.accounts.household"
      value: value
    }
    return this.http.put(`${this.environment.getApiUrl()}/property/${publicId}`, body);
  }



  getProperty() {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/property`)
      )
    ).subscribe((properties:any) => {
        let contact: Contact
       properties.map(property => {
          if (property['publicId'] === 'MAIN_COLOR') {
            this.mandantPrimary$.next(property.value)
          } else if (property['publicId'] === 'THEME_COLOR') {
            this.mandantSecondary$.next(property.value)
          } else if (property['publicId'] === 'logo') {
            this.logo$.next(`${this.environment.getApiUrlLogoBaeder()}${property.value}`)
            //  this.logo$.next(`${this.environment.getApiUrlLogo()}${property.value}/file/?imageWidth=200`)
          } else if (property['publicId'] === 'component_web_landing_page') {
            this.component_web_landing_page$.next(JSON.parse(property.value))
          } else if (property['publicId'] === 'component_advent') {
            this.component_advent$.next(JSON.parse(property.value))
          } else if (property['publicId'] === 'component_web_intro_landing_page') {
            this.component_web_intro_landing_page$.next(JSON.parse(property.value))
          } else if (property['publicId'] === 'component_app_service_center') {
            this.component_app_service_center$.next(JSON.parse(property.value))
          } else if (property['publicId'] === 'phone.contact') {
            contact = { ...contact, phone: property.value }
          } else if (property['publicId'] === 'fax.contact') {
            contact = { ...contact, fax: property.value }
          } else if (property['publicId'] === 'info.mail.address') {
            contact = { ...contact, mail: property.value }
          } else if (property['publicId'] === 'max.accounts.household') {
            this.maxAccountsHousehold$.next(property.value)
          } else if (property['publicId'] === 'formally_Addressing') {
            this.formallyAddressing$.next(JSON.parse(property.value))
          } else if (property['publicId'] === 'portal_label') {
            this.portal_label$.next(property.value)
          } else if (property['publicId'] === 'favicon.path') {
            //Slice off mandantID and /
            this.favicon$.next(property.value.slice(4))
          }
        })
        this.properties$.next(properties)
        this.contact$.next(contact);
      })
  }
  getClientName(): Observable<any> {
    return this.http.get<ClientResource>(`${this.environment.getApiUrl()}`)
  }

  getAdventEventIDs(): Observable<any> {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/property/component_advent/`)
      )
    )
  }




}
